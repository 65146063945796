import React, { ReactNode, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { GetUserResponse } from 'App/api/endpoints/users/responses';
import { useTranslation } from 'react-i18next';
import UserGeneralTab from './userGeneralTab/UserGeneralTab';
import UserSecurityTab from './userSecurityTab/UserSecurityTab';

interface GetUserTabsProps {
	user: GetUserResponse;
}

export const GetUserTabs = ({ user }: GetUserTabsProps) => {
	interface AvailableTab {
		key: string;
		content: ReactNode;
		tab: string;
	}

	const { t } = useTranslation('page');

	const availableTabs = [
		{
			key: 'k1',
			tab: t('AdminPages.UsersPages.TabGeneral'),
			content: <UserGeneralTab user={user} />,
		},
		{
			key: 'k2',
			tab: t('AdminPages.UsersPages.TabSecurity'),
			content: <UserSecurityTab user={user} />,
		},
	] as AvailableTab[];

	const [state, setState] = useState<AvailableTab>(availableTabs.find((a) => a.key === 'k1'));

	const onTabChange = (key: string) => {
		setState(availableTabs.find((a) => a.key === key));
	};

	return (
		<Row className='mt-3' justify='center'>
			<Col xs={23} md={18} xl={14}>
				<Card
					tabList={availableTabs}
					activeTabKey={state.key}
					onTabChange={(key) => {
						onTabChange(key);
					}}
				>
					{state.content}
				</Card>
			</Col>
		</Row>
	);
};
