import { StarOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './UserHome.less';

const UserHome = () => {
	return (
		<div className='user-home'>
			<Row justify='center' className='pt-5' gutter={[8, 24]}>
				<Col xs={24}>
					<Typography.Title level={1} className='user-home__title text-center'>
						Oferty konkursów dla przedsiębiorstw
					</Typography.Title>
				</Col>

				<Col xs={22} sm={11} md={7} lg={6} xl={4}>
					<Row gutter={[8, 8]} justify='center'>
						<Col xs={24} className='text-center'>
							<Typography.Title className='user-home__subtitle' level={4}>
								Przeglądaj oferty
							</Typography.Title>
						</Col>
						<Col xs={24} className='text-center'>
							<Link to='/offers'>
								<Button block type='primary' size='large'>
									Oferty
								</Button>
							</Link>
						</Col>
						<Col xs={24} className='text-center'>
							<Link to='/offers/bookmarks'>
								<Button block type='primary' ghost size='large' icon={<StarOutlined />}>
									Zapisane
								</Button>
							</Link>
						</Col>
					</Row>
				</Col>

				<Col xs={22} sm={11} md={7} lg={6} xl={4}>
					<Row gutter={[8, 8]} justify='center'>
						<Col xs={24} className='text-center'>
							<Typography.Title className='user-home__subtitle' level={4}>
								Napisz do nas!
							</Typography.Title>
						</Col>

						<Col xs={24} className='text-center'>
							<Link to='/chat'>
								<Button block type='ghost' size='large'>
									Wiadomości
								</Button>
							</Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default UserHome;
