import { Modal } from 'antd';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import { OfferFormValues } from 'App/common/components/Offers/offerForm/models/offerFormValues';
import OfferForm from 'App/common/components/Offers/offerForm/OfferForm';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface EditOfferModalProps {
	visible: boolean;
	updating: boolean;
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	selectedOffer: OfferForGetOffersResponse;
	onCancel: () => void;
	onUpdateOffer: (values: OfferFormValues) => void;
}

const EditOfferModal = ({
	visible,
	updating,
	availableIndustries,
	availableContestTypes,
	selectedOffer,
	onCancel,
	onUpdateOffer,
}: EditOfferModalProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			destroyOnClose
			footer={null}
			closable
			title='Edycja oferty'
			width={isMobile ? '100%' : 800}
		>
			<OfferForm
				initialValues={selectedOffer}
				mode={'edit'}
				loading={updating}
				onFinish={onUpdateOffer}
				availableIndustries={availableIndustries}
				availableContestTypes={availableContestTypes}
			/>
		</Modal>
	);
};

export default EditOfferModal;
