import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetContestTypesResponse } from 'App/api/endpoints/contestTypes/responses';
import { GetIndustriesResponse } from 'App/api/endpoints/industries/responses';
import { GetOffersResponse } from 'App/api/endpoints/offers/responses';
import { StatusType } from 'App/types/requestStatus';
import { adminOffersInitialState, AdminOffersState } from './offers.state';

const { Failed, Loading, Success } = StatusType;

export const adminOffersSlice = createSlice({
	name: 'admin-offers',
	initialState: adminOffersInitialState,
	reducers: {
		getOffersStart: (state: AdminOffersState) => {
			state.status.getOffers = Loading;
		},
		getOffersSuccess(state: AdminOffersState, action: PayloadAction<GetOffersResponse>) {
			state.status.getOffers = Success;
			const { offers, ...params } = action.payload;
			state.offers = offers;
			state.getOffersParams = params;
		},
		getOffersFailure(state: AdminOffersState) {
			state.status.getOffers = Failed;
		},

		createOfferStart: (state: AdminOffersState) => {
			state.status.createOffer = Loading;
		},
		createOfferSuccess(state: AdminOffersState) {
			state.status.createOffer = Success;
		},
		createOfferFailure(state: AdminOffersState) {
			state.status.createOffer = Failed;
		},

		updateOfferStart: (state: AdminOffersState) => {
			state.status.updateOffer = Loading;
		},
		updateOfferSuccess(state: AdminOffersState) {
			state.status.updateOffer = Success;
		},
		updateOfferFailure(state: AdminOffersState) {
			state.status.updateOffer = Failed;
		},

		deleteOfferStart: (state: AdminOffersState) => {
			state.status.deleteOffer = Loading;
		},
		deleteOfferSuccess(state: AdminOffersState) {
			state.status.deleteOffer = Success;
		},
		deleteOfferFailure(state: AdminOffersState) {
			state.status.deleteOffer = Failed;
		},

		getAvailableIndustriesStart: (state: AdminOffersState) => {
			state.status.getIndustries = Loading;
		},
		getAvailableIndustriesSuccess(state: AdminOffersState, action: PayloadAction<GetIndustriesResponse>) {
			state.status.getIndustries = Success;
			state.availableIndustries = action.payload.industries;
		},
		getAvailableIndustriesFailure(state: AdminOffersState) {
			state.status.getIndustries = Failed;
		},

		getAvailableContestTypesStart: (state: AdminOffersState) => {
			state.status.getContestTypes = Loading;
		},
		getAvailableContestTypesSuccess(state: AdminOffersState, action: PayloadAction<GetContestTypesResponse>) {
			state.status.getContestTypes = Success;
			state.availableContestTypes = action.payload.contestTypes;
		},
		getAvailableContestTypesFailure(state: AdminOffersState) {
			state.status.getContestTypes = Failed;
		},

		cleanUpOffersState(state: AdminOffersState) {
			state.status.getOffers = adminOffersInitialState.status.getOffers;
			state.status.createOffer = adminOffersInitialState.status.createOffer;
			state.status.getIndustries = adminOffersInitialState.status.getIndustries;
			state.status.getContestTypes = adminOffersInitialState.status.getContestTypes;
			state.status.updateOffer = adminOffersInitialState.status.updateOffer;
			state.status.deleteOffer = adminOffersInitialState.status.deleteOffer;

			state.offers = adminOffersInitialState.offers;
			state.getOffersParams = adminOffersInitialState.getOffersParams;
			state.availableIndustries = adminOffersInitialState.availableIndustries;
			state.availableContestTypes = adminOffersInitialState.availableContestTypes;
		},
	},
});

export default adminOffersSlice;

export const {
	getOffersStart,
	getOffersSuccess,
	getOffersFailure,

	createOfferStart,
	createOfferSuccess,
	createOfferFailure,

	updateOfferStart,
	updateOfferSuccess,
	updateOfferFailure,

	deleteOfferStart,
	deleteOfferSuccess,
	deleteOfferFailure,

	getAvailableIndustriesStart,
	getAvailableIndustriesSuccess,
	getAvailableIndustriesFailure,

	getAvailableContestTypesStart,
	getAvailableContestTypesSuccess,
	getAvailableContestTypesFailure,

	cleanUpOffersState,
} = adminOffersSlice.actions;
