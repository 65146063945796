import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface NotFoundContainerProps extends RouteComponentProps {}

const NotFoundContainer = ({ history }: NotFoundContainerProps) => {
	const buttonGoBackHomeOnClick = () => history.push('/');
	const { t } = useTranslation(['page', 'common']);

	return (
		<Result
			status='404'
			title='404'
			subTitle={t('ResultsPages.NotFoundWeAreSorry')}
			extra={
				<Button type='primary' onClick={buttonGoBackHomeOnClick}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		></Result>
	);
};

export default NotFoundContainer;
