import { ChatMessageForGetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import { OfferSearcherFormValues } from 'App/common/components/Offers/offerSearcher/models/offerSearcherFormValues';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface UserState {
	status: {
		getOffers: StatusType;
		getContestTypes: StatusType;
		getIndustries: StatusType;
		toggleBookmark: StatusType;
		rateOffer: StatusType;
		getChatMessages: StatusType;
		sendChatMessage: StatusType;
	};

	offers: OfferForGetOffersResponse[];
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	getOffersParams: OfferSearcherFormValues;
	chatMessages: ChatMessageForGetChatMessagesResponse[];
}

export const userInitialState: UserState = {
	status: {
		getOffers: Initial,
		getIndustries: Initial,
		getContestTypes: Initial,
		toggleBookmark: Initial,
		rateOffer: Initial,
		sendChatMessage: Initial,
		getChatMessages: Initial,
	},

	offers: [],
	availableIndustries: [],
	availableContestTypes: [],
	getOffersParams: {
		searchContestTypeId: null,
		searchDescription: null,
		searchName: null,
		searchIndustryId: null,
		searchBookmarksOnly: null,
	},
	chatMessages: [],
};
