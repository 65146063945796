import { IFilterItem, IOrderByItem } from 'App/types/pagination/pagination';

export const tableUtils = {
	// funkcja pomocnicza do ustawiania stanu filtracji w tabeli
	manageTableFilters(filters: any, columnKey: string) {
		return filters[columnKey] || null;
	},
	// funkcja pomocnicza do ustawiania stanu sortowania w tabeli
	manageTableOrderBy(orderBy: any, columnKey: string) {
		return (
			(orderBy.columnKey === columnKey && orderBy.order) ||
			(Array.isArray(orderBy) &&
				orderBy.find((c) => c.columnKey === columnKey) &&
				orderBy.find((c) => c.columnKey === columnKey).order)
		);
	},

	// funkcja mapująca filtry z requestu/responsa na filtry tabeli antd
	requestFiltersToAntdFilters(filters: IFilterItem[]) {
		if (!filters) return {};

		let filtersForTable = {};
		filters.forEach((filter) => {
			filtersForTable[filter.field] = filter.values;
		});
		return filtersForTable;
	},

	// funkcja mapująca orderby z requestu/responsa na orderby tabeli antd
	requestOrderByToAntdOrderBy(orderBy: IOrderByItem[]) {
		if (!orderBy || orderBy.length <= 0) return {};

		let orderByForTable = [];
		orderBy.forEach((ob, index) => {
			if (ob !== null) {
				const direction = ob.direction === 'Ascending' ? 'ascend' : 'descend';

				orderByForTable.push({
					column: {
						dataIndex: ob.field,
						key: ob.field,
						sortOrder: direction,
						sorter: {
							multiple: index,
						},
					},
					columnKey: ob.field,
					field: ob.field,
					order: direction,
				});
			}
		});

		return orderByForTable;
	},
};
