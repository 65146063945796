import React from 'react';
import { Route } from 'react-router';
import BookmarksContainer from './views/bookmarks/BookmarksContainer';
import OffersContainer from './views/offers/OffersContainer';

const OfferPages = [
	<Route key='/offers' exact path='/offers' component={OffersContainer} />,
	<Route key='/offers/bookmarks' exact path='/offers/bookmarks' component={BookmarksContainer} />,
];

export default OfferPages;
