import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined, FileTextOutlined, FolderOpenOutlined, MessageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const AdminNavbarContainer = () => {
	const { t } = useTranslation('page');

	const location = useLocation();

	interface NavbarItems {
		key: string;
		subkeys: string[];
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const navbarItems = [
		{
			key: 'admin/users',
			subkeys: ['admin-users-fallback', '/admin/users', '/admin/users/create'],
		},
		{
			key: 'admin/offers',
			subkeys: ['admin-offers-fallback', '/admin/offers'],
		},
		{
			key: 'admin/logs',
			subkeys: ['/admin/logs'],
		},
		{
			key: 'admin/chats',
			subkeys: ['admin-chats-fallback', '/admin/chats'],
		},
	] as NavbarItems[];

	const [activeNavbarItemKey, setActiveNavbarItemKey] = useState('');
	const [activeSubKey, setActiveSubKey] = useState('');
	const [loading, setLoading] = useState(true);

	// ustawienie automatycznego mapowania ścieżki URL na rozwinięcie SubMenu oraz zaznaczenie Menu.Itema
	useEffect(() => {
		setLoading(true);
		const navbarItem = navbarItems.find((navbarItem) => location.pathname.includes(navbarItem.key));
		setActiveNavbarItemKey(navbarItem.key);

		if (navbarItem) {
			const subKey = navbarItem.subkeys.find((subkey) => subkey.includes(location.pathname));
			if (subKey === undefined) {
				setActiveSubKey(navbarItem.subkeys[0]);
			} else {
				setActiveSubKey(subKey);
			}
		}
		setLoading(false);
	}, [navbarItems, location.pathname]);

	const usersSubMenu = (
		<Menu.SubMenu key={navbarItems[0].key} icon={<UserOutlined />} title={t('AdminPages.AdminLayout.Users')}>
			<Menu.Item key={navbarItems[0].subkeys[1]}>
				<Link to={navbarItems[0].subkeys[1]}>{t('AdminPages.AdminLayout.UserList')}</Link>
			</Menu.Item>
			<Menu.Item key={navbarItems[0].subkeys[0]} hidden />
		</Menu.SubMenu>
	);

	const offersSubMenu = (
		<Menu.SubMenu key={navbarItems[1].key} icon={<FolderOpenOutlined />} title='Oferty'>
			<Menu.Item key={navbarItems[1].subkeys[1]}>
				<Link to={navbarItems[1].subkeys[1]}>Oferty</Link>
			</Menu.Item>
			<Menu.Item key={navbarItems[1].subkeys[0]} hidden />
		</Menu.SubMenu>
	);

	const logsSubMenu = (
		<Menu.SubMenu key={navbarItems[2].key} icon={<FileTextOutlined />} title={t('AdminPages.AdminLayout.Logs')}>
			<Menu.Item key={navbarItems[2].subkeys[0]}>
				<Link to={navbarItems[2].subkeys[0]}>{t('AdminPages.AdminLayout.LogList')}</Link>
			</Menu.Item>
		</Menu.SubMenu>
	);

	const chatsSubMenu = (
		<Menu.SubMenu key={navbarItems[3].key} icon={<MessageOutlined />} title={'Wiadomości'}>
			<Menu.Item key={navbarItems[3].subkeys[1]}>
				<Link to={navbarItems[3].subkeys[1]}>Wiadomości</Link>
			</Menu.Item>
			<Menu.Item key={navbarItems[3].subkeys[0]} hidden />
		</Menu.SubMenu>
	);

	return (
		!loading && (
			<Menu className='h-100' defaultOpenKeys={[activeNavbarItemKey]} selectedKeys={[activeSubKey]} mode='inline'>
				{offersSubMenu}
				{usersSubMenu}
				{chatsSubMenu}
				{logsSubMenu}
			</Menu>
		)
	);
};

export default AdminNavbarContainer;
