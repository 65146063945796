import { Form, Input, Button } from 'antd';
import { SetUserPasswordRequest } from 'App/api/endpoints/users/requests';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SetUserPasswordFormProps {
	onFinish: (values: SetUserPasswordRequest) => void;
	loading: boolean;
}

const SetUserPasswordForm = ({ onFinish, loading }: SetUserPasswordFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common', 'models']);
	const { password, confirmPassword } = formRules.user;

	return (
		<Form layout='vertical' className='px-3' onFinish={onFinish}>
			<Form.Item
				label={t('models:User.Labels.Password')}
				messageVariables={{ arg: t('models:User.Labels.Password') }}
				name='newPassword'
				rules={password()}
			>
				<Input.Password placeholder={t('page:AdminPages.UsersPages.EnterNewPassword')} />
			</Form.Item>

			<Form.Item
				label={t('models:User.Labels.ConfirmPassword')}
				messageVariables={{ arg: t('models:User.Labels.ConfirmPassword') }}
				name='confirmNewPassword'
				rules={confirmPassword('newPassword')}
			>
				<Input.Password placeholder={t('page:AdminPages.UsersPages.EnterNewPassword')} />
			</Form.Item>

			<Form.Item>
				<Button block type='primary' htmlType='submit' loading={loading}>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default SetUserPasswordForm;
