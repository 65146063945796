import { Modal } from 'antd';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { OfferFormValues } from 'App/common/components/Offers/offerForm/models/offerFormValues';
import OfferForm from 'App/common/components/Offers/offerForm/OfferForm';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface CreateOfferModalProps {
	visible: boolean;
	creating: boolean;
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	onCancel: () => void;
	onCreateOffer: (values: OfferFormValues) => void;
}

const CreateOfferModal = ({
	visible,
	creating,
	availableIndustries,
	availableContestTypes,
	onCancel,
	onCreateOffer,
}: CreateOfferModalProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			destroyOnClose
			footer={null}
			closable
			title='Nowa oferta'
			width={isMobile ? '100%' : 800}
		>
			<OfferForm
				mode={'create'}
				loading={creating}
				onFinish={onCreateOffer}
				availableIndustries={availableIndustries}
				availableContestTypes={availableContestTypes}
			/>
		</Modal>
	);
};

export default CreateOfferModal;
