import { List, Row, Col, Typography } from 'antd';
import { ChatMessageForGetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import React from 'react';

interface ChatListProps {
	chatMessages: ChatMessageForGetChatMessagesResponse[];
	fetchingMessages: boolean;
}

const ChatList = ({ chatMessages, fetchingMessages }: ChatListProps) => {
	return (
		<List
			dataSource={chatMessages}
			style={{ maxHeight: '50vh', overflowY: 'scroll' }}
			loading={fetchingMessages}
			renderItem={(item) => (
				<List.Item key={item.id}>
					<Row className='w-100' justify='space-between'>
						<Col>
							<Typography.Text strong>
								{item.sender.firstName} {item.sender.lastName}
							</Typography.Text>
						</Col>
						<Col>
							<Typography.Text disabled>
								{dateTimeUtils.getLocalDateTimeString(item.sentAtUtc)}
							</Typography.Text>
						</Col>
						<Col xs={24}>{item.message}</Col>
					</Row>
				</List.Item>
			)}
		/>
	);
};

export default ChatList;
