import { RootState } from 'App/globalState/root.reducer';
import Role from 'App/types/role';
import React from 'react';
import { useSelector } from 'react-redux';
import AdminHome from './admin/AdminHome';
import AnonymousHome from './anonymous/AnonymousHome';
import './HomeContainer.less';
import UserHome from './user/UserHome';

const HomeContainer = () => {
	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);

	if (!accountDetails) {
		return <AnonymousHome />;
	}

	if (accountDetails.roles.includes(Role.Admin)) {
		return <AdminHome />;
	}

	if (accountDetails.roles.includes(Role.User)) {
		return <UserHome />;
	}

	return <AnonymousHome />;
};

export default HomeContainer;
