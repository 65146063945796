import ProtectedRoute from 'App/common/components/ProtectedRoute';
import Role from 'App/types/role';
import React from 'react';
import { Switch } from 'react-router-dom';
import ChatContainer from './views/chat/ChatContainer';
import ChatsContainer from './views/chats/ChatsContainer';

const AdminChatsPages = () => {
	return (
		<Switch>
			<ProtectedRoute acceptedRoles={[Role.Admin]} exact path='/admin/chats' component={ChatsContainer} />
			<ProtectedRoute path='/admin/chats/:chatId' acceptedRoles={[Role.Admin]} component={ChatContainer} />
		</Switch>
	);
};

export default AdminChatsPages;
