import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const HomeMenuItem = (props) => {
	return (
		<Menu.Item key='/' {...props}>
			<Link to='/'>
				Inservices
				{/* <img src={logo} height={logoHeight} alt={t('Navbar.Home')} /> */}
			</Link>
		</Menu.Item>
	);
};

export default HomeMenuItem;
