import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MessagesSearchFormProps {
	loading: boolean;
	handleSearch: (values: MessagesSearchFormValues) => void;
	setCreateMessageModalVisibility: (visible: boolean) => void;
	setMessagesType: (messageType: 'All' | 'Upcoming' | 'Current') => void;
}

const MessagesSearchForm = ({
	loading,
	handleSearch,
	setCreateMessageModalVisibility,
	setMessagesType,
}: MessagesSearchFormProps) => {
	const { t } = useTranslation();

	return (
		<Form onFinish={handleSearch}>
			<Row>
				<Col className='p-1' xs={24} sm={12} md={8} lg={5} xl={4} xxl={3}>
					<Form.Item noStyle>
						<Button block onClick={() => setCreateMessageModalVisibility(true)} icon={<PlusOutlined />}>
							{t('AdminPages.MaintenanceMessagesPages.NewMessageButton')}
						</Button>
					</Form.Item>
				</Col>

				<Col className='p-1' xs={24} sm={12} md={8} lg={3} xl={3} xxl={2}>
					<Form.Item noStyle>
						<Button block onClick={() => setMessagesType('All')}>
							{t('AdminPages.MaintenanceMessagesPages.GetAllMessages')}
						</Button>
					</Form.Item>
				</Col>

				<Col className='p-1' xs={12} sm={12} md={8} lg={4} xl={3} xxl={2}>
					<Form.Item noStyle>
						<Button block onClick={() => setMessagesType('Upcoming')}>
							{t('AdminPages.MaintenanceMessagesPages.GetUpcomingMessages')}
						</Button>
					</Form.Item>
				</Col>

				<Col className='p-1' xs={12} sm={12} md={8} lg={3} xl={3} xxl={2}>
					<Form.Item noStyle>
						<Button block onClick={() => setMessagesType('Current')}>
							{t('AdminPages.MaintenanceMessagesPages.GetCurrentMessages')}
						</Button>
					</Form.Item>
				</Col>

				<Col
					xs={21}
					sm={22}
					md={14}
					lg={8}
					xl={{ span: 8, offset: 2 }}
					xxl={{ span: 6, offset: 8 }}
					className='p-1'
				>
					<Form.Item noStyle name='query'>
						<Input allowClear />
					</Form.Item>
				</Col>
				<Col xs={3} sm={2} lg={1} className='p-1'>
					<Form.Item noStyle>
						<Button loading={loading} block type='primary' htmlType='submit' icon={<SearchOutlined />} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default MessagesSearchForm;
