import { IAppConfig } from './app.config.d';

const appConfig: IAppConfig = {
	urls: {
		backend: {
			login: '/auth/login',
			refreshToken: '/auth/refresh-token',
		},
		frontend: {
			baseUrlToIncludeInEmail: 'https://inservices-cca6c.web.app',
			confirmEmail: '/account/confirm-email',
			resetPassword: '/account/reset-password',
			signIn: '/sign-in',
			signUp: '/sign-up',
			forgotPassword: '/account/forgot-password',
			resendConfirmationEmail: '/account/resend-confirmation-email',
		},
	},
};

const frontendUrl = appConfig.urls.frontend;

export const urlsToWhichNotReturn = [
	frontendUrl.resetPassword,
	frontendUrl.forgotPassword,
	frontendUrl.signUp,
	frontendUrl.signIn,
	frontendUrl.confirmEmail,
	frontendUrl.resendConfirmationEmail,
];

export default appConfig;
