import agent from 'App/api/agent/agent';
import {
	ChangePasswordRequest,
	ConfirmEmailRequest,
	ForgotPasswordRequest,
	ResendConfirmationEmailRequest,
	ResetPasswordRequest,
	UpdateAccountDetailsRequest,
} from 'App/api/endpoints/account/requests';
import { getAccountDetails } from 'App/globalState/account/account.global.thunk';
import { AppThunk } from 'App/globalState/store';
import {
	changePasswordFailure,
	changePasswordStart,
	changePasswordSuccess,
	confirmEmailFailure,
	confirmEmailStart,
	confirmEmailSuccess,
	deleteAccountFailure,
	deleteAccountStart,
	deleteAccountSuccess,
	forgotPasswordFailure,
	forgotPasswordStart,
	forgotPasswordSuccess,
	resendConfirmationEmailFailure,
	resendConfirmationEmailStart,
	resendConfirmationEmailSuccess,
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
	updateAccountDetailsFailure,
	updateAccountDetailsStart,
	updateAccountDetailsSuccess,
} from './account.slice';

export const confirmEmail =
	(request: ConfirmEmailRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(confirmEmailStart());
		agent.Account.confirmEmail(request)
			.then(() => {
				dispatch(confirmEmailSuccess());
				if (onSuccess) onSuccess();
			})
			.catch(() => dispatch(confirmEmailFailure()));
	};

export const forgotPassword =
	(request: ForgotPasswordRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(forgotPasswordStart());
		agent.Account.forgotPassword(request)
			.then(() => {
				dispatch(forgotPasswordSuccess());
				if (onSuccess) onSuccess();
			})
			.catch(() => dispatch(forgotPasswordFailure()));
	};

export const updateAccountDetails =
	(request: UpdateAccountDetailsRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(updateAccountDetailsStart());
		agent.Account.updateAccountDetails(request)
			.then(() => {
				dispatch(updateAccountDetailsSuccess());
				dispatch(getAccountDetails());
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(() => dispatch(updateAccountDetailsFailure()));
	};

export const resetPassword =
	(request: ResetPasswordRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(resetPasswordStart());
		agent.Account.resetPassword(request)
			.then(() => {
				dispatch(resetPasswordSuccess());
				if (onSuccess) onSuccess();
			})
			.catch(() => dispatch(resetPasswordFailure()));
	};

export const deleteAccount =
	(request: UpdateAccountDetailsRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(deleteAccountStart());
		agent.Account.updateAccountDetails(request)
			.then(() => {
				dispatch(deleteAccountSuccess());
				if (onSuccess) onSuccess();
			})
			.catch(() => dispatch(deleteAccountFailure()));
	};

export const resendConfirmationEmail =
	(request: ResendConfirmationEmailRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(resendConfirmationEmailStart());
		agent.Account.resendConfirmationEmail(request)
			.then(() => {
				dispatch(resendConfirmationEmailSuccess());
				if (onSuccess) onSuccess();
			})
			.catch(() => dispatch(resendConfirmationEmailFailure()));
	};

export const changePassword =
	(request: ChangePasswordRequest, onSuccess?: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(changePasswordStart());
		agent.Account.changePassword(request)
			.then(() => {
				if (onSuccess) onSuccess();
				dispatch(changePasswordSuccess());
			})
			.catch(() => dispatch(changePasswordFailure()));
	};
