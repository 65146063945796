import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UsersSearchFormValues } from './utils/usersSearchFormValues';

interface UsersSearchFormProps {
	loading: boolean;
	handleSearch: (values: UsersSearchFormValues) => void;
	showUserFormModal: (userId?: string) => void;
}

const UsersSearchForm = ({ loading, handleSearch, showUserFormModal }: UsersSearchFormProps) => {
	const { t } = useTranslation();

	return (
		<Form onFinish={handleSearch}>
			<Row>
				<Col xs={24} sm={12} md={6} lg={5} xl={5} xxl={3} className='p-1'>
					<Form.Item noStyle>
						<Button
							block
							onClick={() => {
								showUserFormModal();
							}}
							icon={<PlusOutlined />}
						>
							{t('AdminPages.UsersPages.NewUserButton')}
						</Button>
					</Form.Item>
				</Col>
				<Col
					xs={21}
					sm={10}
					md={16}
					lg={{ span: 10, offset: 8 }}
					xl={{ span: 6, offset: 12 }}
					xxl={{ span: 6, offset: 14 }}
					className='p-1'
				>
					<Form.Item noStyle name='query'>
						<Input allowClear />
					</Form.Item>
				</Col>
				<Col xs={3} sm={2} lg={1} className='p-1'>
					<Form.Item noStyle>
						<Button loading={loading} block type='primary' htmlType='submit' icon={<SearchOutlined />} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default UsersSearchForm;
