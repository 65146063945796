import { AxiosError } from 'axios';
import { notification } from 'antd';
import { DetailedError } from './interfaces/detailedError';
import i18n from '../../../../i18n';
import { urlsToWhichNotReturn } from 'app.config';
import agent from '../agent';
import axios from 'axios';
import history from '../../../../app.history';
import { refreshTokenSuccess } from 'App/globalState/auth/auth.global.slice';
import store from 'App/globalState/store';

let firstTime = true;

export const errorHandler = async (error: AxiosError) => {
	const { status } = error.response || {};

	let tokenRefreshedSuccessfully = false;

	switch (status) {
		case 400:
			handleBadRequest(error);
			break;
		case 401:
			tokenRefreshedSuccessfully = await handleUnauthorized();
			break;
		case 403:
			handleForbidden(error);
			break;
		case 404:
			handleNotFound(error);
			break;
		case 500:
			handleInternalServerError(error);
			break;
		default:
			break;
	}

	return tokenRefreshedSuccessfully ? axios.request(error.config) : Promise.reject(error);
};

function handleBadRequest(error: AxiosError<any>) {
	const { data } = error.response || {};
	// mamy 2 typy 400-tek (teoretycznie)
	// zwykła 400 - zawiera obiekt errors, który zawiera obiekty detailedErrors oraz commonErrors
	// foramularzowa - nie zawiera obiektu errors ani detailedErrors ani commonErrors,
	// ale zawiera słownik o nazwach kluczy takich, jak pole jest nazwane, czyli np. mając w formularzu
	// inputy dla pól roles oraz firstName, to dostaniemy słowniki o kluczu roles oraz firstName, a dla nich mamy jako valuesy
	// już podaną arrayke detailedErrorów

	if (data.errors) {
		// 400-tka zwykła(nieformularzowa)
		let mainErrorObject = data.errors;
		if (mainErrorObject.detailedErrors) {
			let detailedErrors = mainErrorObject.detailedErrors as DetailedError[];
			detailedErrors.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		}

		if (mainErrorObject.commonErrors) {
			console.log(mainErrorObject.commonErrors);
			if (!mainErrorObject.detailedErrors)
				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t('common:Errors.AnErrorOccured'),
				});
		}
	} else if (data.detailedErrors) {
		// 400-tka formularzowa
		Object.keys(data.detailedErrors).forEach((key) => {
			let detailedErrorsForCurrentKey = data.detailedErrors[key] as DetailedError[];
			detailedErrorsForCurrentKey.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		});
	} else {
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: i18n.t('common:Errors.AnErrorOccured'),
		});
	}
}

function handleInternalServerError(error: AxiosError<any>) {
	notification['error']({
		message: i18n.t('common:Errors.Error'),
		description: i18n.t('common:Errors.ServerSideError'),
	});
	console.log(`500: ${error.response.data}`);

	history.push('/500');
}

async function handleUnauthorized(): Promise<boolean> {
	let browserUrl = history.location.pathname;

	if (!urlsToWhichNotReturn.some((url) => browserUrl.includes(url))) localStorage.setItem('returnUrl', browserUrl);

	try {
		const refreshTokenResponse = await agent.Auth.refreshToken();
		firstTime = false;

		store.dispatch(refreshTokenSuccess(refreshTokenResponse));
		return true;
	} catch {
		let appStarting = store.getState().global.auth.flags.appStarting;

		//jeśli wystąpił błąd i apka nie startuje
		//lub
		//jeśli wystąpił błąd i apka startuje i to nie jest jej pierwszy request
		if (!appStarting || (appStarting && !firstTime)) {
			history.push('/sign-in');
		}

		firstTime = false;
		return false;
	}
}

function handleForbidden(error: AxiosError<any>) {
	console.log('403: ' + error.response);
	notification['error']({
		message: i18n.t('common:Errors.Error'),
		description: i18n.t('common:Errors.DontHaveAccess'),
	});

	history.push('/403');
}

function handleNotFound(error: AxiosError<any>) {
	const { data } = error.response || {};

	if (data.errors) {
		// 404 z kodem błędu
		let mainErrorObject = data.errors;
		if (mainErrorObject.detailedErrors) {
			let detailedErrors = mainErrorObject.detailedErrors as DetailedError[];
			detailedErrors.forEach((detailedError) => {
				const { errorCode, errorParameters } = detailedError;

				const errorCodeWithDot = errorCode.replace('-', '.');
				const translationKey = `detailedErrors:${errorCodeWithDot}.DescriptionFormatter`;

				const args = errorParameters;

				notification['error']({
					message: i18n.t('common:Errors.Error'),
					description: i18n.t(translationKey, { args }),
				});
			});
		}

		if (mainErrorObject.commonErrors) {
			console.log(mainErrorObject.commonErrors);
		}
	} else {
		notification['error']({
			message: i18n.t('common:Errors.Error'),
			description: i18n.t('common:Errors.NoResourceWasFound'),
		});
	}
	console.log('404: ' + error);
}
