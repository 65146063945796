import { Button, Col, Divider, PageHeader, Result, Row } from 'antd';
import { OfferSearcherFormValues } from 'App/common/components/Offers/offerSearcher/models/offerSearcherFormValues';
import OfferSearcher from 'App/common/components/Offers/offerSearcher/OfferSearcher';
import OffersList from 'App/common/components/Offers/offersList/OffersList';
import { RootState } from 'App/globalState/root.reducer';
import { cleanUpOffersState } from 'App/pages/userPages/state/user.slice';
import {
	getAvailableContestTypes,
	getAvailableIndustries,
	getOffers,
	toggleBookmark,
} from 'App/pages/userPages/state/user.thunk';
import StatusType from 'App/types/requestStatus';
import Role from 'App/types/role';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { Loading, Failed } = StatusType;

const OffersContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const offers = useSelector((state: RootState) => state.pages.user.offers);
	const getOffersStatus = useSelector((state: RootState) => state.pages.user.status.getOffers);
	const getOffersParams = useSelector((state: RootState) => state.pages.user.getOffersParams);

	const availableContestTypes = useSelector((state: RootState) => state.pages.user.availableContestTypes);
	const getContestTypesStatus = useSelector((state: RootState) => state.pages.user.status.getContestTypes);

	const availableIndustries = useSelector((state: RootState) => state.pages.user.availableIndustries);
	const getIndustriesStatus = useSelector((state: RootState) => state.pages.user.status.getIndustries);

	useEffect(() => {
		dispatch(
			getOffers({
				searchContestTypeId: null,
				searchDescription: null,
				searchName: null,
				searchIndustryId: null,
				searchBookmarksOnly: null,
			})
		);
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
		return () => {
			dispatch(cleanUpOffersState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRetry = () => {
		dispatch(getOffers(getOffersParams));
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
	};

	const handleOfferSearch = (values: OfferSearcherFormValues) => {
		dispatch(getOffers(values));
	};

	const handleToggleBookmark = (offerId: string) => {
		const onSuccess = () => {
			dispatch(getOffers(getOffersParams));
		};

		dispatch(toggleBookmark(offerId, onSuccess));
	};

	if (getOffersStatus === Failed)
		return (
			<>
				<PageHeader onBack={() => history.goBack()} title='Oferty' />
				<Result
					status='info'
					title='Wystąpił błąd podczas pobierania ofert'
					extra={
						<Button type='primary' onClick={handleRetry}>
							Spróbuj ponownie
						</Button>
					}
				/>
			</>
		);

	return (
		<>
			<Row className='px-3'>
				<Col xs={24}>
					<PageHeader onBack={() => history.goBack()} title='Oferty' subTitle='Lista ofert' />
					<Divider className='mt-0 my-3' />
				</Col>
				<Col xs={24}>
					<OfferSearcher
						initialValues={getOffersParams}
						availableIndustries={availableIndustries}
						availableContestTypes={availableContestTypes}
						loading={getOffersStatus === Loading}
						onFinish={handleOfferSearch}
					/>
				</Col>
				<Col xs={24} lg={20} xl={18} xxl={14}>
					<OffersList
						role={Role.User}
						loading={
							getOffersStatus === Loading ||
							getIndustriesStatus === Loading ||
							getContestTypesStatus === Loading
						}
						availableContestTypes={availableContestTypes}
						availableIndustries={availableIndustries}
						offers={offers}
						onToggleBookmark={handleToggleBookmark}
					/>
				</Col>
			</Row>
		</>
	);
};

export default OffersContainer;
