import { PageHeader, Result, Button, Row, Col, Divider, message } from 'antd';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import OffersList from 'App/common/components/Offers/offersList/OffersList';
import { RateOfferFormValues } from 'App/common/components/Offers/rateOfferForm/models/rateOfferFormValues';
import { RootState } from 'App/globalState/root.reducer';
import { cleanUpOffersState } from 'App/pages/userPages/state/user.slice';
import {
	getOffers,
	getAvailableContestTypes,
	getAvailableIndustries,
	toggleBookmark,
	rateOffer,
} from 'App/pages/userPages/state/user.thunk';
import StatusType from 'App/types/requestStatus';
import Role from 'App/types/role';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import RateOfferModal from '../rateOffer/modals/RateOfferModal';

const { Loading, Failed } = StatusType;

const BookmarksContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const offers = useSelector((state: RootState) => state.pages.user.offers);
	const getOffersStatus = useSelector((state: RootState) => state.pages.user.status.getOffers);
	const getOffersParams = useSelector((state: RootState) => state.pages.user.getOffersParams);

	const availableContestTypes = useSelector((state: RootState) => state.pages.user.availableContestTypes);
	const getContestTypesStatus = useSelector((state: RootState) => state.pages.user.status.getContestTypes);

	const availableIndustries = useSelector((state: RootState) => state.pages.user.availableIndustries);
	const getIndustriesStatus = useSelector((state: RootState) => state.pages.user.status.getIndustries);

	const rateOfferStatus = useSelector((state: RootState) => state.pages.user.status.rateOffer);

	const [rateOfferModalVisible, setRateOfferModalVisible] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState<OfferForGetOffersResponse | null>(null);

	useEffect(() => {
		dispatch(
			getOffers({
				searchContestTypeId: null,
				searchDescription: null,
				searchName: null,
				searchIndustryId: null,
				searchBookmarksOnly: true,
			})
		);
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
		return () => {
			dispatch(cleanUpOffersState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRetry = () => {
		dispatch(getOffers(getOffersParams));
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
	};

	const handleToggleBookmark = (offerId: string) => {
		const onSuccess = () => {
			dispatch(getOffers(getOffersParams));
		};

		dispatch(toggleBookmark(offerId, onSuccess));
	};

	const handleHideRateOfferModal = () => {
		setRateOfferModalVisible(false);
		setSelectedOffer(null);
	};

	const handleShowRateOfferModal = (offer: OfferForGetOffersResponse) => {
		setSelectedOffer(offer);
		setRateOfferModalVisible(true);
	};

	const handleRateOffer = (values: RateOfferFormValues) => {
		const onSuccess = () => {
			message.success('Dziękujemy za wypełnienie ankiety!');
			dispatch(getOffers(getOffersParams));
			handleHideRateOfferModal();
		};
		dispatch(rateOffer(selectedOffer.id, values, onSuccess));
	};

	if (getOffersStatus === Failed)
		return (
			<>
				<PageHeader onBack={() => history.goBack()} title='Oferty' />
				<Result
					status='info'
					title='Wystąpił błąd podczas pobierania ofert'
					extra={
						<Button type='primary' onClick={handleRetry}>
							Spróbuj ponownie
						</Button>
					}
				/>
			</>
		);

	return (
		<>
			<RateOfferModal
				visible={rateOfferModalVisible}
				loading={rateOfferStatus === Loading}
				onCancel={handleHideRateOfferModal}
				onRateOffer={handleRateOffer}
			/>
			<Row className='px-3'>
				<Col xs={24}>
					<PageHeader onBack={() => history.goBack()} title='Oferty' subTitle='Lista ofert' />
					<Divider className='mt-0 my-3' />
				</Col>
				<Col xs={24} lg={20} xl={18} xxl={14}>
					<OffersList
						role={Role.User}
						loading={
							getOffersStatus === Loading ||
							getIndustriesStatus === Loading ||
							getContestTypesStatus === Loading
						}
						bookmarkMode
						availableContestTypes={availableContestTypes}
						availableIndustries={availableIndustries}
						offers={offers}
						onToggleBookmark={handleToggleBookmark}
						onShowRateOfferModal={handleShowRateOfferModal}
					/>
				</Col>
			</Row>
		</>
	);
};

export default BookmarksContainer;
