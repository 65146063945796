import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import StatusType from 'App/types/requestStatus';
import { globalAccountInitialState, GlobalAccountState } from './account.global.state';

const { Failed, Success, Loading } = StatusType;

const globalAccountSlice = createSlice({
	name: 'global-account',
	initialState: globalAccountInitialState,
	reducers: {
		getAccountDetailsStart: (state: GlobalAccountState) => {
			state.status.getAccountDetails = Loading;
			state.accountDetails = globalAccountInitialState.accountDetails;
		},
		getAccountDetailsSuccess: (state: GlobalAccountState, action: PayloadAction<GetAccountDetailsResponse>) => {
			state.status.getAccountDetails = Success;
			state.accountDetails = action.payload;
		},
		getAccountDetailsFailure: (state: GlobalAccountState) => {
			state.status.getAccountDetails = Failed;
		},

		clearAccountStart: (state: GlobalAccountState) => {
			state.accountDetails = null;
			state.status.getAccountDetails = StatusType.Initial;
		},
	},
});

export default globalAccountSlice;

export const {
	getAccountDetailsStart,
	getAccountDetailsSuccess,
	getAccountDetailsFailure,

	clearAccountStart,
} = globalAccountSlice.actions;
