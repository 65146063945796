import StatusType from 'App/types/requestStatus';
const { Initial } = StatusType;

export interface AccountState {
	status: {
		confirmEmail: StatusType;
		forgotPassword: StatusType;
		updateAccountDetails: StatusType;
		resetPassword: StatusType;
		deleteAccount: StatusType;
		resendConfirmationEmail: StatusType;
		changePassword: StatusType;
	};
}

export const accountInitialState: AccountState = {
	status: {
		confirmEmail: Initial,
		forgotPassword: Initial,
		updateAccountDetails: Initial,
		resetPassword: Initial,
		deleteAccount: Initial,
		resendConfirmationEmail: Initial,
		changePassword: Initial,
	},
};
