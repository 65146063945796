import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { GetAvailableChatsResponse } from 'App/api/endpoints/chats/responses';
import { StatusType } from 'App/types/requestStatus';
import { adminChatsInitialState, AdminChatsState } from './chats.state';

const { Failed, Loading, Success, Initial } = StatusType;

export const adminChatsSlice = createSlice({
	name: 'admin-chats',
	initialState: adminChatsInitialState,
	reducers: {
		getAvailableChatsStart: (state: AdminChatsState) => {
			state.status.getAvailableChats = Loading;
		},
		getAvailableChatsSuccess(state: AdminChatsState, action: PayloadAction<GetAvailableChatsResponse>) {
			state.status.getAvailableChats = Success;
			state.availableChats = action.payload.availableChats;
		},
		getAvailableChatsFailure(state: AdminChatsState) {
			state.status.getAvailableChats = Failed;
		},

		getChatMessagesStart: (state: AdminChatsState) => {
			state.status.getChatMessages = Loading;
		},
		getChatMessagesSuccess(state: AdminChatsState, action: PayloadAction<GetChatMessagesResponse>) {
			state.status.getChatMessages = Success;
			state.chatMessages = action.payload.chatMessages;
		},
		getChatMessagesFailure(state: AdminChatsState) {
			state.status.getChatMessages = Failed;
		},

		sendChatMessageStart: (state: AdminChatsState) => {
			state.status.sendChatMessage = Loading;
		},
		sendChatMessageSuccess(state: AdminChatsState) {
			state.status.sendChatMessage = Success;
		},
		sendChatMessageFailure(state: AdminChatsState) {
			state.status.sendChatMessage = Failed;
		},

		cleanUpChatMessagesState(state: AdminChatsState) {
			state.status.getChatMessages = Initial;
			state.status.sendChatMessage = Initial;

			state.chatMessages = [];
		},

		cleanUpAvailableChats(state: AdminChatsState) {
			state.availableChats = [];
			state.status.getAvailableChats = Initial;
		},
	},
});

export default adminChatsSlice;

export const {
	getAvailableChatsStart,
	getAvailableChatsSuccess,
	getAvailableChatsFailure,

	sendChatMessageStart,
	sendChatMessageSuccess,
	sendChatMessageFailure,

	getChatMessagesStart,
	getChatMessagesSuccess,
	getChatMessagesFailure,

	cleanUpChatMessagesState,
	cleanUpAvailableChats,
} = adminChatsSlice.actions;
