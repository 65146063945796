import { ChatMessageForGetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { ChatForGetAvailableChats } from 'App/api/endpoints/chats/responses';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface AdminChatsState {
	status: {
		getAvailableChats: StatusType;
		getChatMessages: StatusType;
		sendChatMessage: StatusType;
	};

	availableChats: ChatForGetAvailableChats[];
	chatMessages: ChatMessageForGetChatMessagesResponse[];
}

export const adminChatsInitialState: AdminChatsState = {
	status: {
		getAvailableChats: Initial,
		getChatMessages: Initial,
		sendChatMessage: Initial,
	},

	availableChats: [],
	chatMessages: [],
};
