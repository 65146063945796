import { Rule } from 'antd/lib/form';
import i18n from 'i18n';

export const userFormRules = {
	firstName: (): Rule[] => [{ required: true, max: 255 }],
	lastName: (): Rule[] => [{ required: true, max: 255 }],
	roles: (): Rule[] => [{ required: true, type: 'array' }],
	isDeleted: (): Rule[] => [{ required: true }],
	email: (): Rule[] => [{ required: true, type: 'email', max: 255 }],
	birthDate: (): Rule[] => [{ required: true }],
	emailLanguage: (): Rule[] => [{ required: true }],
	lockoutEnd: (): Rule[] => [
		{ type: 'date' },
		({ getFieldValue }) => ({
			validator(rule, value) {
				console.log(value);
				if (!getFieldValue('lockoutEnabled')) {
					return Promise.resolve();
				}

				if (value) {
					return Promise.resolve();
				}

				return Promise.reject(
					i18n
						.t('form:ValidationMessages.Required')
						// eslint-disable-next-line no-template-curly-in-string
						.replace('${arg}', i18n.t('models:User.Labels.LockoutEnd'))
				);
			},
		}),
	],
	lockoutEnabled: (): Rule[] => [{ required: true }],
	emailConfirmed: (): Rule[] => [{ required: true }],
	phoneConfirmed: (): Rule[] => [{ required: true }],
	phoneNumber: (): Rule[] => [
		{
			pattern: RegExp(
				'^(\\+\\s?)?((?<!\\+.*)\\(\\+?\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+)([\\s\\-\\.]?(\\(\\d+([\\s\\-\\.]?\\d+)?\\)|\\d+))*(\\s?(x|ext\\.?)\\s?\\d+)?$',
				'im'
			),
			message: i18n.t('form:ValidationMessages.Custom.PhoneNumber', {
				arg: i18n.t('models:User.Labels.PhoneNumber'),
			}),
		},
	],
	loginPassword: (): Rule[] => [{ required: true }],
	password: (): Rule[] => [
		{
			pattern: RegExp('\\d'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainNumber', {
				arg: i18n.t('models:User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('[*@!#%&()^~{}]+'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainSpecial', {
				arg: i18n.t('models:User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('(?=.*[a-z])'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainLowercase', {
				arg: i18n.t('models:User.Labels.Password'),
			}),
		},
		{
			pattern: RegExp('(?=.*[A-Z])'),
			message: i18n.t('form:ValidationMessages.Custom.MustContainUppercase', {
				arg: i18n.t('models:User.Labels.Password'),
			}),
		},
		{
			min: 6,
		},
		{
			required: true,
			max: 100,
		},
	],
	confirmPassword: (fieldToCompare: 'password' | 'newPassword'): Rule[] => [
		{
			required: true,
		},
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue(fieldToCompare) === value) {
					return Promise.resolve();
				}

				const arg = [i18n.t(`models:User.Labels.Password`), i18n.t('models:User.Labels.ConfirmPassword')];
				return Promise.reject(i18n.t('form:ValidationMessages.Custom.PasswordMismatch', { arg }));
			},
		}),
	],
};
