import agent from 'App/api/agent/agent';
import { SendChatMessageRequest } from 'App/api/endpoints/chats/responses';
import { RateOfferRequest } from 'App/api/endpoints/offers/requests';
import { GetOffersRequest } from 'App/api/endpoints/offers/requests/getOffersRequest';
import { AppThunk } from 'App/globalState/store';
import {
	getAvailableContestTypesFailure,
	getAvailableContestTypesStart,
	getAvailableContestTypesSuccess,
	getAvailableIndustriesFailure,
	getAvailableIndustriesStart,
	getAvailableIndustriesSuccess,
	getChatMessagesFailure,
	getChatMessagesStart,
	getChatMessagesSuccess,
	getOffersFailure,
	getOffersStart,
	getOffersSuccess,
	rateOfferFailure,
	rateOfferStart,
	rateOfferSuccess,
	sendChatMessageFailure,
	sendChatMessageStart,
	sendChatMessageSuccess,
	toggleBookmarkFailure,
	toggleBookmarkStart,
	toggleBookmarkSuccess,
} from './user.slice';

export const getOffers =
	(params: GetOffersRequest): AppThunk =>
	async (dispatch) => {
		dispatch(getOffersStart());
		agent.Offers.getOffers(params)
			.then((response) => dispatch(getOffersSuccess(response)))
			.catch(() => dispatch(getOffersFailure()));
	};

export const getAvailableIndustries = (): AppThunk => async (dispatch) => {
	dispatch(getAvailableIndustriesStart());
	agent.Industries.getIndustries()
		.then((response) => dispatch(getAvailableIndustriesSuccess(response)))
		.catch(() => dispatch(getAvailableIndustriesFailure()));
};

export const getAvailableContestTypes = (): AppThunk => async (dispatch) => {
	dispatch(getAvailableContestTypesStart());
	agent.ContestTypes.getContestTypes()
		.then((response) => dispatch(getAvailableContestTypesSuccess(response)))
		.catch(() => dispatch(getAvailableContestTypesFailure()));
};

export const toggleBookmark =
	(offerId: string, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(toggleBookmarkStart());
		agent.Offers.toggleBookmark(offerId)
			.then(() => {
				dispatch(toggleBookmarkSuccess());
				onSuccess();
			})
			.catch(() => dispatch(toggleBookmarkFailure()));
	};

export const rateOffer =
	(offerId: string, request: RateOfferRequest, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(rateOfferStart());
		agent.Offers.rateOffer(offerId, request)
			.then(() => {
				dispatch(rateOfferSuccess());
				onSuccess();
			})
			.catch(() => dispatch(rateOfferFailure()));
	};

export const sendChatMessage =
	(chatId: string, request: SendChatMessageRequest, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(sendChatMessageStart());
		agent.Chats.sendChatMessage(chatId, request)
			.then(() => {
				onSuccess();
				dispatch(sendChatMessageSuccess());
			})
			.catch(() => dispatch(sendChatMessageFailure()));
	};

export const getChatMessages =
	(chatId: string): AppThunk =>
	async (dispatch) => {
		dispatch(getChatMessagesStart());
		agent.Chats.getChatMessages(chatId)
			.then((response) => dispatch(getChatMessagesSuccess(response)))
			.catch(() => dispatch(getChatMessagesFailure()));
	};
