import { MessageOutlined } from '@ant-design/icons';
import { Button, Col, Divider, List, PageHeader, Result, Row, Typography } from 'antd';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { cleanUpAvailableChats, cleanUpChatMessagesState } from '../../state/chats.slice';
import { getAvailableChats } from '../../state/chats.thunk';

const { Failed, Loading } = StatusType;

const ChatsContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const availableChats = useSelector((state: RootState) => state.pages.admin.chats.availableChats);
	const getAvailableChatsStatus = useSelector((state: RootState) => state.pages.admin.chats.status.getAvailableChats);

	useEffect(() => {
		dispatch(getAvailableChats());
		return () => {
			dispatch(cleanUpAvailableChats());
			dispatch(cleanUpChatMessagesState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRetry = () => {
		dispatch(getAvailableChats());
	};

	if (getAvailableChatsStatus === Failed)
		return (
			<>
				<PageHeader onBack={() => history.goBack()} title='Wiadomości' />
				<Result
					status='info'
					title='Wystąpił błąd podczas pobierania wiadomości'
					extra={
						<Button type='primary' onClick={handleRetry}>
							Spróbuj ponownie
						</Button>
					}
				/>
			</>
		);

	return (
		<>
			<Row className='px-3'>
				<Col xs={24}>
					<PageHeader onBack={() => history.goBack()} title='Wiadomości' />
					<Divider className='mt-0 my-3' />
				</Col>
				<Col xs={24}>
					<List
						loading={getAvailableChatsStatus === Loading}
						dataSource={availableChats}
						renderItem={(item) => (
							<List.Item key={item.id}>
								<Row align='middle' gutter={[8, 8]}>
									<Col>
										<Typography.Text strong>
											{item.customer.firstName} {item.customer.lastName}
										</Typography.Text>
									</Col>
									<Col>
										<Typography.Text disabled> {item.totalMessages} wiadomości</Typography.Text>
									</Col>
									<Col>
										<Link to={`/admin/chats/${item.id}`}>
											<Button icon={<MessageOutlined />}>Czat</Button>
										</Link>
									</Col>
								</Row>
							</List.Item>
						)}
					/>
				</Col>
			</Row>
		</>
	);
};

export default ChatsContainer;
