import {
	BarChartOutlined,
	DeleteOutlined,
	EditOutlined,
	QuestionCircleOutlined,
	StarOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import List from 'antd/lib/list';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import Role from 'App/types/role';
import React from 'react';
import { Link } from 'react-router-dom';

interface OffersListProps {
	role: Role;
	loading: boolean;
	bookmarkMode?: boolean;
	offers: OfferForGetOffersResponse[];
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	onShowEditModal?: (offer: OfferForGetOffersResponse) => void;
	onDeleteOffer?: (offerId: string) => void;
	onToggleBookmark?: (offerId: string) => void;
	onShowRateOfferModal?: (offer: OfferForGetOffersResponse) => void;
	onShowOfferRatingsModal?: (selectedOffer: OfferForGetOffersResponse) => void;
}

const OffersList = ({
	role,
	loading,
	offers,
	availableIndustries,
	availableContestTypes,
	bookmarkMode,
	onDeleteOffer,
	onShowEditModal,
	onToggleBookmark,
	onShowRateOfferModal,
	onShowOfferRatingsModal,
}: OffersListProps) => {
	const getActions = (offer: OfferForGetOffersResponse) => {
		if (role === Role.Admin) {
			return [
				<Button
					key='statistics'
					onClick={() => {
						onShowOfferRatingsModal(offer);
					}}
					icon={<BarChartOutlined />}
				/>,
				<Button icon={<EditOutlined />} key='edit' onClick={() => onShowEditModal(offer)} />,
				<Popconfirm
					title='Czy na pewno?'
					onConfirm={() => onDeleteOffer(offer.id)}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
				>
					<Button danger key='delete' icon={<DeleteOutlined />} />
				</Popconfirm>,
			];
		}
		return [
			<Link to='/chat'>
				<Button key='send-request'>Wyślij zapytanie</Button>
			</Link>,
			bookmarkMode && (
				<Button
					key='rate'
					disabled={offer.isRated}
					icon={<BarChartOutlined />}
					onClick={() => {
						if (!offer.isRated) {
							onShowRateOfferModal(offer);
						}
					}}
				>
					{offer.isRated ? 'Oceniono' : 'Oceń'}
				</Button>
			),
			<Button
				key='bookmark'
				type={offer.isBookmark ? 'primary' : 'default'}
				onClick={() => {
					onToggleBookmark(offer.id);
				}}
				icon={<StarOutlined />}
			/>,
		];
	};

	return (
		<List
			loading={loading}
			dataSource={offers}
			renderItem={(offer: OfferForGetOffersResponse) => (
				<List.Item key={offer.id} actions={getActions(offer)}>
					<List.Item.Meta
						title={offer.name}
						description={
							<>
								{offer.description}
								<br />
								<br />
								{availableContestTypes.find((a) => a.id === offer.contestTypeId)?.name}
								<br />
								{availableIndustries.find((a) => a.id === offer.industryId)?.name}
							</>
						}
					/>
				</List.Item>
			)}
		/>
	);
};

export default OffersList;
