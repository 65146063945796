import { HttpStatusCodeResponse } from 'App/types/httpResponse';
import { requests } from '../../agent/agent';
import { GetChatMessagesResponse } from './requests';
import { GetAvailableChatsResponse, SendChatMessageRequest } from './responses';

export const ChatsApi = {
	getChatMessages: (chatId: string): Promise<GetChatMessagesResponse> => requests.get(`/chats/${chatId}`),
	sendChatMessage: (chatId: string, body: SendChatMessageRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/chats/${chatId}`, body),
	getAvailableChats: (): Promise<GetAvailableChatsResponse> => requests.get(`/chats`),
};
