import { MessageForGetCurrentMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface MaintenanceMessagesState {
	status: {
		getCurrentMessages: StatusType;
	};

	currentMessages: MessageForGetCurrentMessagesResponse[];
}

export const maintenanceMessagesInitialState: MaintenanceMessagesState = {
	status: {
		getCurrentMessages: Initial,
	},

	currentMessages: [],
};
