import agent from 'App/api/agent/agent';
import { SendChatMessageRequest } from 'App/api/endpoints/chats/responses';
import { AppThunk } from 'App/globalState/store';
import {
	getAvailableChatsFailure,
	getAvailableChatsStart,
	getAvailableChatsSuccess,
	getChatMessagesFailure,
	getChatMessagesStart,
	getChatMessagesSuccess,
	sendChatMessageFailure,
	sendChatMessageStart,
	sendChatMessageSuccess,
} from './chats.slice';

export const sendChatMessage =
	(chatId: string, request: SendChatMessageRequest, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(sendChatMessageStart());
		agent.Chats.sendChatMessage(chatId, request)
			.then(() => {
				onSuccess();
				dispatch(sendChatMessageSuccess());
			})
			.catch(() => dispatch(sendChatMessageFailure()));
	};

export const getChatMessages =
	(chatId: string): AppThunk =>
	async (dispatch) => {
		dispatch(getChatMessagesStart());
		agent.Chats.getChatMessages(chatId)
			.then((response) => dispatch(getChatMessagesSuccess(response)))
			.catch(() => dispatch(getChatMessagesFailure()));
	};

export const getAvailableChats = (): AppThunk => async (dispatch) => {
	dispatch(getAvailableChatsStart());
	agent.Chats.getAvailableChats()
		.then((response) => dispatch(getAvailableChatsSuccess(response)))
		.catch(() => dispatch(getAvailableChatsFailure()));
};
