import { Modal } from 'antd';
import { SetUserPasswordRequest } from 'App/api/endpoints/users/requests';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SetUserPasswordForm from '../../forms/setUserPasswordForm/SetUserPasswordForm';

interface SetUserPasswordModalProps {
	showModal: boolean;
	onCancel: () => void;
	onSave: (values: SetUserPasswordRequest) => void;
	loading: boolean;
}

const SetUserPasswordModal = ({ showModal, onCancel, onSave, loading }: SetUserPasswordModalProps) => {
	const { t } = useTranslation(['page', 'common']);

	return (
		<Modal
			title={t('common:Actions.SetPassword')}
			visible={showModal}
			footer={null}
			maskClosable
			onCancel={onCancel}
			destroyOnClose
		>
			<SetUserPasswordForm onFinish={onSave} loading={loading} />
		</Modal>
	);
};

export default SetUserPasswordModal;
