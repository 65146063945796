import { Form, Input, Select, Button, Divider, Col, Row, DatePicker } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useForm } from 'antd/lib/form/Form';
import { formRules } from 'App/common/formRules/formRules';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import { languages } from 'i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFormValues } from './models/userFormValues';

interface UserFormProps {
	onFinish: (values: UserFormValues) => void;
	initialValues?: UserFormValues;
	loading: boolean;
}

const UserForm = ({ loading, initialValues, onFinish }: UserFormProps) => {
	const {
		firstName,
		lastName,
		roles,
		isDeleted,
		emailConfirmed,
		lockoutEnabled,
		lockoutEnd,
		email,
		password,
		emailLanguage,
		phoneNumber,
		phoneConfirmed,
	} = formRules.user;

	const { t } = useTranslation(['form', 'common']);
	const [form] = useForm();
	const [islockoutEnabled, setIsLockoutEnabled] = useState(form.getFieldValue('lockoutEnabled'));

	const editMode = initialValues.id !== undefined;

	const onLockoutEnabledChange = (e: CheckboxChangeEvent) => {
		setIsLockoutEnabled(e.target.checked);
	};

	return (
		<Form form={form} layout='vertical' initialValues={initialValues} onFinish={onFinish}>
			<Row gutter={[24, 8]}>
				<Col xs={24} md={12}>
					{!editMode && (
						<>
							<Divider>{t('page:AdminPages.UsersPages.Login')}</Divider>
							<Form.Item
								messageVariables={{ arg: t('models:User.Labels.Email') }}
								label={t('models:User.Labels.Email')}
								name='email'
								rules={email()}
							>
								<Input placeholder={t('models:User.Placeholders.Email')} />
							</Form.Item>
							<Form.Item
								label={t('models:User.Labels.Password')}
								messageVariables={{ arg: t('models:User.Labels.Password') }}
								name='password'
								rules={password()}
							>
								<Input type='password' placeholder={t('models:User.Placeholders.Password')} />
							</Form.Item>
						</>
					)}

					<Divider>{t('page:AdminPages.UsersPages.PersonalData')}</Divider>
					<Form.Item
						label={t('models:User.Labels.FirstName')}
						messageVariables={{ arg: t('models:User.Labels.FirstName') }}
						name='firstName'
						rules={firstName()}
					>
						<Input placeholder={t('models:User.Placeholders.FirstName')} />
					</Form.Item>
					<Form.Item
						label={t('models:User.Labels.LastName')}
						messageVariables={{ arg: t('models:User.Labels.LastName') }}
						name='lastName'
						rules={lastName()}
					>
						<Input placeholder={t('models:User.Placeholders.LastName')} />
					</Form.Item>
					<Form.Item
						label={t('models:User.Labels.PhoneNumber')}
						messageVariables={{ arg: t('models:User.Labels.PhoneNumber') }}
						name='phoneNumber'
						rules={phoneNumber()}
					>
						<Input placeholder={t('models:User.Placeholders.PhoneNumber')} />
					</Form.Item>
				</Col>

				<Col xs={24} md={12}>
					<Divider>{t('page:AdminPages.UsersPages.Security')}</Divider>
					<Form.Item
						name='roles'
						label={t('models:User.Labels.Roles')}
						messageVariables={{ arg: t('models:User.Labels.Roles') }}
						rules={roles()}
					>
						<Select mode='multiple' placeholder={t('models:User.Placeholders.SelectRoles')}>
							<Select.Option value='User'>{t('common:Roles.User')}</Select.Option>
							<Select.Option value='Administrator'>{t('common:Roles.Administrator')}</Select.Option>
						</Select>
					</Form.Item>

					{islockoutEnabled && (
						<Form.Item
							name='lockoutEndUtc'
							label={t('models:User.Labels.LockoutEnd')}
							messageVariables={{ arg: t('models:User.Labels.LockoutEnd') }}
							rules={lockoutEnd()}
						>
							<DatePicker
								className='w-100'
								placeholder={t('models:User.Placeholders.LockoutEnd')}
								showTime
								showMinute
								showHour
								format={dateTimeUtils.formats.YearMonthDayHourMinute}
							/>
						</Form.Item>
					)}

					<Row>
						<Col xs={12}>
							<Form.Item
								name='lockoutEnabled'
								messageVariables={{ arg: t('models:User.Labels.LockoutEnabled') }}
								rules={lockoutEnabled()}
								valuePropName='checked'
							>
								<Checkbox onChange={onLockoutEnabledChange}>
									{t('models:User.Labels.LockoutEnabled')}
								</Checkbox>
							</Form.Item>
						</Col>

						<Col xs={12}>
							<Form.Item
								name='phoneNumberConfirmed'
								messageVariables={{ arg: t('models:User.Labels.PhoneConfirmed') }}
								rules={phoneConfirmed()}
								valuePropName='checked'
							>
								<Checkbox>{t('models:User.Labels.PhoneConfirmed')}</Checkbox>
							</Form.Item>
						</Col>

						<Col xs={12}>
							<Form.Item
								name='isDeleted'
								messageVariables={{ arg: t('models:User.Labels.IsDeleted') }}
								rules={isDeleted()}
								valuePropName='checked'
							>
								<Checkbox>{t('models:User.Labels.IsDeleted')}</Checkbox>
							</Form.Item>
						</Col>

						<Col xs={12}>
							<Form.Item
								name='emailConfirmed'
								messageVariables={{ arg: t('models:User.Labels.EmailConfirmed') }}
								rules={emailConfirmed()}
								valuePropName='checked'
							>
								<Checkbox>{t('models:User.Labels.EmailConfirmed')}</Checkbox>
							</Form.Item>
						</Col>
					</Row>

					<Divider>{t('page:AdminPages.UsersPages.Other')}</Divider>

					{!editMode && (
						<Form.Item
							name='language'
							label={t('models:User.Labels.EmailLanguage')}
							messageVariables={{ arg: t('models:User.Labels.EmailLanguage') }}
							rules={emailLanguage()}
							tooltip={t('page:AdminPages.UsersPages.EmailLanguageDescription')}
						>
							<Select placeholder={t('models:User.Placeholders.EmailLanguage')} disabled>
								{languages.map((language) => (
									<Select.Option key={language} value={language}>
										{language}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					)}
				</Col>
			</Row>

			<Row justify='center'>
				<Col xs={24} md={12}>
					<Form.Item>
						<Button block loading={loading} type='primary' htmlType='submit'>
							{t('common:Actions.Save')}
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default UserForm;
