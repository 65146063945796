import { Row, Col, Typography, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const AnonymousHome = () => {
	return (
		<div className='home-container'>
			<Row justify='center' align='middle' className='h-100'>
				<Col xs={24}>
					<Typography.Title level={1} className='home-container__title'>
						Inservices
					</Typography.Title>

					<Typography.Title level={2} className='home-container__subtitle'>
						oferty konkursów dla przedsiębiorstw
					</Typography.Title>
					<Row justify='center' align='middle'>
						<Col>
							<Link to='/sign-in'>
								<Button ghost>Zaloguj się</Button>
							</Link>
						</Col>
						<Col>
							<Typography.Text strong className='home-container__text mx-3'>
								lub
							</Typography.Text>
						</Col>
						<Col>
							<Link to='/sign-up'>
								<Button type='default'>Utwórz konto</Button>
							</Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default AnonymousHome;
