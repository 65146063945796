import { maintenanceMessageFormRules } from './models/maintenanceMessage.rules';
import { offerFormRules } from './models/offer';
import { userFormRules } from './models/user.rules';
import { offerSearcherFormRules } from './others/offerSearcher.rules';
import { rateOfferFormRules } from './others/rateOffer.rules';

export const formRules = {
	user: userFormRules,
	maintenanceMessages: maintenanceMessageFormRules,
	offer: offerFormRules,
	others: {
		offerSearcher: offerSearcherFormRules,
		rateOffer: rateOfferFormRules,
	},
};
