import { UserAddOutlined, LoginOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import HomeMenuItem from './HomeMenuItem';

const NotLoggedInNavbar = () => {
	const { t } = useTranslation('page');
	const location = useLocation();

	const signUpMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-up' icon={<UserAddOutlined />} className={isMobile ? '' : ''}>
			<Link to='/sign-up'>{t('Navbar.SignUp')}</Link>
		</Menu.Item>
	);

	const signInMenuItem = (isMobile: boolean) => (
		<Menu.Item key='/sign-in' icon={<LoginOutlined />} className={isMobile ? '' : ''}>
			<Link to='/sign-in'>{t('Navbar.SignIn')}</Link>
		</Menu.Item>
	);

	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<Menu.SubMenu key='notlogged-mobile' icon={<MenuOutlined className='mr-0' />}>
								{signUpMenuItem(true)}
								{signInMenuItem(true)}
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>
			<Default>
				<Row justify='space-between'>
					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
						</Menu>
					</Col>

					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							{signUpMenuItem(false)}
							{signInMenuItem(false)}
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default NotLoggedInNavbar;
