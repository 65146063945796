import { Alert, Modal } from 'antd';
import { RateOfferFormValues } from 'App/common/components/Offers/rateOfferForm/models/rateOfferFormValues';
import RateOfferForm from 'App/common/components/Offers/rateOfferForm/RateOfferForm';
import React from 'react';

interface RateOfferModalProps {
	visible: boolean;
	loading: boolean;
	onCancel: () => void;
	onRateOffer: (values: RateOfferFormValues) => void;
}

const RateOfferModal = ({ visible, loading, onCancel, onRateOffer }: RateOfferModalProps) => {
	return (
		<Modal visible={visible} onCancel={onCancel} destroyOnClose footer={null} closable title='Ankieta'>
			<Alert
				message='Twoje opinia jest dla nas bardzo ważna. Jesteśmy wdzięczni za każdą opinię, którą nam wysyłasz!'
				type='success'
				className='mb-3'
			/>
			<RateOfferForm loading={loading} onFinish={onRateOffer} />
		</Modal>
	);
};
export default RateOfferModal;
