import { Modal } from 'antd';
import { GetUserResponse } from 'App/api/endpoints/users/responses';
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { UserFormValues } from '../../userForm/models/userFormValues';
import UserForm from '../../userForm/UserForm';

interface UpdateUserModalProps {
	visible: boolean;
	loading: boolean;
	selectedUser: GetUserResponse;
	onUpdate: (values: UserFormValues) => void;
	onCancel: () => void;
}

const UpdateUserModal = ({ visible, loading, selectedUser, onCancel, onUpdate }: UpdateUserModalProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });
	const { t } = useTranslation();

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			destroyOnClose
			footer={null}
			closable
			title={t('page:AdminPages.UsersPages.UpdateUserModalTitle')}
			width={isMobile ? '100%' : 800}
		>
			<UserForm
				loading={loading}
				onFinish={onUpdate}
				initialValues={{
					...selectedUser,
					lockoutEndUtc: selectedUser?.lockoutEndUtc ? moment(selectedUser.lockoutEndUtc) : '',
				}}
			/>
		</Modal>
	);
};

export default UpdateUserModal;
