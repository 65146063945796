import { Button, Form, Rate } from 'antd';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react';
import { RateOfferFormValues } from './models/rateOfferFormValues';

interface RateOfferFormProps {
	loading: boolean;
	onFinish: (values: RateOfferFormValues) => void;
}

const { profitability, usefulness, difficulty } = formRules.others.rateOffer;

const RateOfferForm = ({ loading, onFinish }: RateOfferFormProps) => {
	const tooltips = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

	return (
		<Form onFinish={onFinish} layout='vertical'>
			<Form.Item
				name='profitability'
				messageVariables={{ arg: 'rentowność' }}
				label='Jak oceniasz rentowność oferty?'
				rules={profitability()}
			>
				<Rate style={{ fontSize: 18 }} tooltips={tooltips} count={10} />
			</Form.Item>

			<Form.Item
				name='usefulness'
				messageVariables={{ arg: 'przydatność' }}
				label='Jak oceniasz przydatność oferty?'
				rules={usefulness()}
			>
				<Rate style={{ fontSize: 18 }} tooltips={tooltips} count={10} />
			</Form.Item>

			<Form.Item
				name='difficulty'
				messageVariables={{ arg: 'trudność' }}
				label='Jak oceniasz trudność w realizacji?'
				rules={difficulty()}
			>
				<Rate style={{ fontSize: 18 }} tooltips={tooltips} count={10} />
			</Form.Item>

			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit'>
					Wyślij
				</Button>
			</Form.Item>
		</Form>
	);
};

export default RateOfferForm;
