import React from 'react';
import { Form, Input, Button } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { formRules } from 'App/common/formRules/formRules';

interface ResetPasswordFormProps extends FormProps {
	loading: boolean;
}

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
	const { t } = useTranslation(['page', 'common']);
	const { loading, ...rest } = props;
	const { password, confirmPassword } = formRules.user;

	return (
		<Form {...rest}>
			<Form.Item
				name='newPassword'
				messageVariables={{ arg: t('page:AccountPages.NewPassword') }}
				rules={password()}
			>
				<Input type='password' placeholder={t('page:AccountPages.NewPassword')} />
			</Form.Item>
			<Form.Item
				name='confirmNewPassword'
				messageVariables={{ arg: t('page:AccountPages.ConfirmNewPassword') }}
				rules={confirmPassword('newPassword')}
			>
				<Input type='password' placeholder={t('page:AccountPages.ConfirmNewPassword')} />
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.Submit')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default ResetPasswordForm;
