import agent from 'App/api/agent/agent';
import { CreateOfferRequest, UpdateOfferRequest } from 'App/api/endpoints/offers/requests';
import { GetOffersRequest } from 'App/api/endpoints/offers/requests/getOffersRequest';
import { AppThunk } from 'App/globalState/store';
import {
	getOffersStart,
	getOffersSuccess,
	getOffersFailure,
	createOfferFailure,
	createOfferStart,
	createOfferSuccess,
	getAvailableIndustriesFailure,
	getAvailableIndustriesStart,
	getAvailableIndustriesSuccess,
	getAvailableContestTypesFailure,
	getAvailableContestTypesStart,
	getAvailableContestTypesSuccess,
	updateOfferFailure,
	updateOfferStart,
	updateOfferSuccess,
	deleteOfferFailure,
	deleteOfferStart,
	deleteOfferSuccess,
} from './offers.slice';

export const getOffers =
	(params: GetOffersRequest): AppThunk =>
	async (dispatch) => {
		dispatch(getOffersStart());
		agent.Offers.getOffers(params)
			.then((response) => dispatch(getOffersSuccess(response)))
			.catch(() => dispatch(getOffersFailure()));
	};

export const createOffer =
	(request: CreateOfferRequest, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(createOfferStart());
		agent.Offers.createOffer(request)
			.then(() => {
				dispatch(createOfferSuccess());
				onSuccess();
			})
			.catch(() => dispatch(createOfferFailure()));
	};

export const getAvailableIndustries = (): AppThunk => async (dispatch) => {
	dispatch(getAvailableIndustriesStart());
	agent.Industries.getIndustries()
		.then((response) => dispatch(getAvailableIndustriesSuccess(response)))
		.catch(() => dispatch(getAvailableIndustriesFailure()));
};

export const getAvailableContestTypes = (): AppThunk => async (dispatch) => {
	dispatch(getAvailableContestTypesStart());
	agent.ContestTypes.getContestTypes()
		.then((response) => dispatch(getAvailableContestTypesSuccess(response)))
		.catch(() => dispatch(getAvailableContestTypesFailure()));
};

export const updateOffer =
	(offerId: string, request: UpdateOfferRequest, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(updateOfferStart());
		agent.Offers.updateOffer(offerId, request)
			.then(() => {
				dispatch(updateOfferSuccess());
				onSuccess();
			})
			.catch(() => dispatch(updateOfferFailure()));
	};

export const deleteOffer =
	(offerId: string, onSuccess: () => void): AppThunk =>
	async (dispatch) => {
		dispatch(deleteOfferStart());
		agent.Offers.deleteOffer(offerId)
			.then(() => {
				onSuccess();
				dispatch(deleteOfferSuccess());
			})
			.catch(() => dispatch(deleteOfferFailure()));
	};
