import { Modal } from 'antd';
import { ChangePasswordRequest } from 'App/api/endpoints/account/requests';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../../forms/changePasswordForm/ChangePasswordForm';

interface ChangePasswordModalProps {
	showModal: boolean;
	onCancel: () => void;
	onSave: (values: ChangePasswordRequest) => void;
	loading: boolean;
}

const ChangePasswordModal = ({ showModal, onCancel, onSave, loading }: ChangePasswordModalProps) => {
	const { t } = useTranslation(['page', 'common']);

	return (
		<Modal
			title={t('common:Actions.SetPassword')}
			visible={showModal}
			footer={null}
			maskClosable
			onCancel={onCancel}
			destroyOnClose
		>
			<ChangePasswordForm onFinish={onSave} loading={loading} />
		</Modal>
	);
};

export default ChangePasswordModal;
