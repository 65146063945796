import { Row, Col, Typography, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './AdminHome.less';

const AdminHome = () => {
	return (
		<div className='admin-home'>
			<Row justify='center' className='pt-5' gutter={[8, 24]}>
				<Col xs={24}>
					<Typography.Title level={1} className='admin-home__title text-center'>
						Panel administratora
					</Typography.Title>
				</Col>

				<Col xs={22} sm={11} md={7} lg={6} xl={4}>
					<Row justify='center'>
						<Col xs={24} className='text-center'>
							<Typography.Title className='admin-home__subtitle' level={4}>
								Oferty
							</Typography.Title>
						</Col>
						<Col xs={24} className='text-center'>
							<Row gutter={[0, 16]}>
								<Col xs={24}>
									<Link to='admin/offers'>
										<Button block type='primary' size='large'>
											Dodaj
										</Button>
									</Link>
								</Col>

								<Col xs={24}>
									<Link to='admin/offers'>
										<Button block type='ghost' size='large'>
											Przeglądaj
										</Button>
									</Link>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>

				<Col xs={22} sm={11} md={7} lg={6} xl={4}>
					<Row justify='center'>
						<Col xs={24} className='text-center'>
							<Typography.Title className='admin-home__subtitle' level={4}>
								Wiadomości
							</Typography.Title>
						</Col>

						<Col xs={24} className='text-center'>
							<Link to='admin/chats'>
								<Button block type='ghost' size='large'>
									Przeglądaj
								</Button>
							</Link>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default AdminHome;
