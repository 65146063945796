import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { authInitialState, AuthState } from './auth.global.state';
import { LoginResponse, RefreshTokenResponse } from 'App/api/endpoints/auth/responses';
import { StatusType } from 'App/types/requestStatus';

const { Failed, Success, Loading } = StatusType;

const globalAuthSlice = createSlice({
	name: 'global-auth',
	initialState: authInitialState,
	reducers: {
		loginStart: (state: AuthState) => {
			state.status.login = Loading;
			state.flags.login = true;
			state.tokens = authInitialState.tokens;
		},
		loginSuccess: (state: AuthState, action: PayloadAction<LoginResponse>) => {
			state.status.login = Success;
			state.tokens = action.payload;
		},
		loginFailure: (state: AuthState) => {
			state.status.login = Failed;
		},
		loginFinish: (state: AuthState) => {
			state.flags.login = authInitialState.flags.login;
		},

		registerStart: (state: AuthState) => {
			state.status.register = Loading;
		},
		registerSuccess: (state: AuthState) => {
			state.status.register = Success;
		},
		registerFailure: (state: AuthState) => {
			state.status.register = Failed;
		},

		logoutStart: (state: AuthState) => {
			state.status.logout = Success;
			state.tokens = authInitialState.tokens;
		},

		refreshTokenStart: (state: AuthState) => {
			state.status.refreshToken = Loading;
			state.tokens = authInitialState.tokens;
		},
		refreshTokenSuccess: (state: AuthState, action: PayloadAction<RefreshTokenResponse>) => {
			state.tokens = action.payload;
			state.status.refreshToken = Success;
		},
		refreshTokenFailure: (state: AuthState) => {
			state.status.refreshToken = Failed;
		},

		appStarted: (state: AuthState) => {
			state.flags.appStarting = false;
		},

		cleanUpAuthStatusStart: (state: AuthState) => {
			state.status = authInitialState.status;
		},
	},
});

export default globalAuthSlice;

export const {
	loginStart,
	loginSuccess,
	loginFailure,
	loginFinish,

	registerStart,
	registerSuccess,
	registerFailure,

	refreshTokenStart,
	refreshTokenSuccess,
	refreshTokenFailure,

	appStarted,

	logoutStart,

	cleanUpAuthStatusStart,
} = globalAuthSlice.actions;
