import { HttpStatusCodeResponse } from 'App/types/httpResponse';
import { requests } from '../../agent/agent';
import { CreateOfferRequest, RateOfferRequest } from './requests';
import { GetOffersRequest } from './requests/getOffersRequest';
import { GetOffersResponse } from './responses';

export const OffersApi = {
	getOffers: (body: GetOffersRequest): Promise<GetOffersResponse> => requests.post(`/offers/search`, body),
	createOffer: (body: CreateOfferRequest): Promise<HttpStatusCodeResponse> => requests.post(`/offers`, body),
	updateOffer: (offerId: string, body: CreateOfferRequest): Promise<HttpStatusCodeResponse> =>
		requests.put(`/offers/${offerId}`, body),
	deleteOffer: (offerId: string): Promise<HttpStatusCodeResponse> => requests.delete(`/offers/${offerId}`),
	toggleBookmark: (offerId: string): Promise<HttpStatusCodeResponse> =>
		requests.post(`/offers/${offerId}/toggle-bookmark`, {}),
	rateOffer: (offerId: string, body: RateOfferRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/offers/${offerId}/rate`, body),
};
