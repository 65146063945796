import { PageHeader, Result, Button, Row, Col, Divider } from 'antd';
import { SendChatMessageRequest } from 'App/api/endpoints/chats/responses';
import Chat from 'App/common/components/Chat/Chat';
import { RootState } from 'App/globalState/root.reducer';
import { cleanUpChatState } from 'App/pages/userPages/state/user.slice';
import { getChatMessages, sendChatMessage } from 'App/pages/userPages/state/user.thunk';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const { Loading, Failed } = StatusType;

const ChatContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);

	const chatMessages = useSelector((state: RootState) => state.pages.user.chatMessages);
	const getChatMessagesStatus = useSelector((state: RootState) => state.pages.user.status.getChatMessages);

	const sendChatMessageStatus = useSelector((state: RootState) => state.pages.user.status.sendChatMessage);

	useEffect(() => {
		dispatch(getChatMessages(accountDetails.id));
		return () => {
			dispatch(cleanUpChatState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRetry = () => {
		dispatch(getChatMessages(accountDetails.id));
	};

	const handleSendChatMessage = (values: SendChatMessageRequest) => {
		const onSuccess = () => {
			handleRetry();
		};
		dispatch(sendChatMessage(accountDetails.id, values, onSuccess));
	};

	if (getChatMessagesStatus === Failed)
		return (
			<>
				<PageHeader onBack={() => history.goBack()} title='Wiadomości' />
				<Result
					status='info'
					title='Wystąpił błąd podczas pobierania wiadomości'
					extra={
						<Button type='primary' onClick={handleRetry}>
							Spróbuj ponownie
						</Button>
					}
				/>
			</>
		);

	return (
		<>
			<Row className='px-3'>
				<Col xs={24}>
					<PageHeader onBack={() => history.goBack()} title='Wiadomości' />
					<Divider className='mt-0 my-3' />
				</Col>
				<Col xs={24}>
					<Chat
						fetchingMessages={getChatMessagesStatus === Loading}
						sendingMessage={sendChatMessageStatus === Loading}
						chatMessages={chatMessages}
						onSendChatMessage={handleSendChatMessage}
						onGetChatMessages={handleRetry}
					/>
				</Col>
			</Row>
		</>
	);
};

export default ChatContainer;
