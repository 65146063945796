import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react';
import { OfferSearcherFormValues } from './models/offerSearcherFormValues';

interface OfferSearcherProps {
	initialValues: OfferSearcherFormValues;
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	loading: boolean;
	onFinish: (values: OfferSearcherFormValues) => void;
}

const { searchName, searchDescription } = formRules.others.offerSearcher;

const OfferSearcher = ({
	initialValues,
	loading,
	availableIndustries,
	availableContestTypes,
	onFinish,
}: OfferSearcherProps) => {
	return (
		<Form
			initialValues={
				initialValues
					? initialValues
					: { searchIndustryId: null, searchContestTypeId: null, searchName: null, searchDescription: null }
			}
			onFinish={onFinish}
			layout='vertical'
		>
			<Row gutter={[8, 0]}>
				<Col xs={24} sm={12} md={12} lg={5}>
					<Form.Item name='searchIndustryId' messageVariables={{ arg: 'Branża' }}>
						<Select
							allowClear
							placeholder='Wybierz branżę'
							options={availableIndustries.map((a) => {
								return { label: a.name, value: a.id };
							})}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={5}>
					<Form.Item name='searchContestTypeId' messageVariables={{ arg: 'Typ konkursu' }}>
						<Select
							allowClear
							placeholder='Wybierz typ konkursu'
							options={availableContestTypes.map((a) => {
								return { label: a.name, value: a.id };
							})}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={10} lg={6}>
					<Form.Item name='searchName' messageVariables={{ arg: 'Nazwa konkursu' }} rules={searchName()}>
						<Input allowClear placeholder='Nazwa konkursu' />
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={10} lg={6}>
					<Form.Item
						name='searchDescription'
						messageVariables={{ arg: 'Dokładna nazwa konkursu' }}
						rules={searchDescription()}
					>
						<Input allowClear placeholder='Dokładna nazwa konkursu' />
					</Form.Item>
				</Col>

				<Col xs={24} md={4} lg={2}>
					<Form.Item>
						<Button block icon={<SearchOutlined />} loading={loading} type='primary' htmlType='submit' />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default OfferSearcher;
