import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OffersListContainer from './views/offersList/OffersListContainer';

const AdminOffersPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/offers' component={OffersListContainer} />
		</Switch>
	);
};

export default AdminOffersPages;
