import { Col, Divider, Modal, Row, Typography } from 'antd';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import React from 'react';

interface OfferRatingsModalProps {
	visible: boolean;
	offer: OfferForGetOffersResponse;
	onCancel: () => void;
}

const OfferRatingsModal = ({ visible, offer, onCancel }: OfferRatingsModalProps) => {
	return (
		<Modal visible={visible} onCancel={onCancel} destroyOnClose footer={null} closable title='Wyniki ankiety'>
			{offer && (
				<Row>
					<Col xs={24}>
						<Typography.Text>Liczba wypełnionych ankiet: {offer.totalVotes}</Typography.Text>
						<Divider className='mt-2 mb-3' />
					</Col>
					<Col xs={24}>
						<Typography.Title level={5}>Jak oceniasz rentowność oferty?</Typography.Title>
						<Typography.Text>Wynik: {offer.avgProfitability.toFixed(2)}</Typography.Text>
						<Divider className='mt-2 mb-3' />
					</Col>
					<Col xs={24}>
						<Typography.Title level={5}>Jak oceniasz przydatność oferty?</Typography.Title>
						<Typography.Text>Wynik: {offer.avgUsefulness.toFixed(2)}</Typography.Text>
						<Divider className='mt-2 mb-3' />
					</Col>
					<Col xs={24}>
						<Typography.Title level={5}>Jak oceniasz trudność w realizacji?</Typography.Title>
						<Typography.Text>Wynik: {offer.avgDifficulty.toFixed(2)}</Typography.Text>
					</Col>
				</Row>
			)}
		</Modal>
	);
};

export default OfferRatingsModal;
