import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { GetContestTypesResponse } from 'App/api/endpoints/contestTypes/responses';
import { GetIndustriesResponse } from 'App/api/endpoints/industries/responses';
import { GetOffersResponse } from 'App/api/endpoints/offers/responses';
import { StatusType } from 'App/types/requestStatus';
import { userInitialState, UserState } from './user.state';

const { Failed, Loading, Success } = StatusType;

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		getOffersStart: (state: UserState) => {
			state.status.getOffers = Loading;
		},
		getOffersSuccess(state: UserState, action: PayloadAction<GetOffersResponse>) {
			state.status.getOffers = Success;
			const { offers, ...params } = action.payload;
			state.offers = offers;
			state.getOffersParams = params;
		},
		getOffersFailure(state: UserState) {
			state.status.getOffers = Failed;
		},

		getAvailableIndustriesStart: (state: UserState) => {
			state.status.getIndustries = Loading;
		},
		getAvailableIndustriesSuccess(state: UserState, action: PayloadAction<GetIndustriesResponse>) {
			state.status.getIndustries = Success;
			state.availableIndustries = action.payload.industries;
		},
		getAvailableIndustriesFailure(state: UserState) {
			state.status.getIndustries = Failed;
		},

		getAvailableContestTypesStart: (state: UserState) => {
			state.status.getContestTypes = Loading;
		},
		getAvailableContestTypesSuccess(state: UserState, action: PayloadAction<GetContestTypesResponse>) {
			state.status.getContestTypes = Success;
			state.availableContestTypes = action.payload.contestTypes;
		},
		getAvailableContestTypesFailure(state: UserState) {
			state.status.getContestTypes = Failed;
		},

		toggleBookmarkStart: (state: UserState) => {
			state.status.toggleBookmark = Loading;
		},
		toggleBookmarkSuccess(state: UserState) {
			state.status.toggleBookmark = Success;
		},
		toggleBookmarkFailure(state: UserState) {
			state.status.toggleBookmark = Failed;
		},

		rateOfferStart: (state: UserState) => {
			state.status.rateOffer = Loading;
		},
		rateOfferSuccess(state: UserState) {
			state.status.rateOffer = Success;
		},
		rateOfferFailure(state: UserState) {
			state.status.rateOffer = Failed;
		},

		getChatMessagesStart: (state: UserState) => {
			state.status.getChatMessages = Loading;
		},
		getChatMessagesSuccess(state: UserState, action: PayloadAction<GetChatMessagesResponse>) {
			state.status.getChatMessages = Success;
			state.chatMessages = action.payload.chatMessages;
		},
		getChatMessagesFailure(state: UserState) {
			state.status.getChatMessages = Failed;
		},

		sendChatMessageStart: (state: UserState) => {
			state.status.sendChatMessage = Loading;
		},
		sendChatMessageSuccess(state: UserState) {
			state.status.sendChatMessage = Success;
		},
		sendChatMessageFailure(state: UserState) {
			state.status.sendChatMessage = Failed;
		},

		cleanUpChatState(state: UserState) {
			state.status.getChatMessages = userInitialState.status.getChatMessages;
			state.status.sendChatMessage = userInitialState.status.sendChatMessage;
			state.chatMessages = userInitialState.chatMessages;
		},

		cleanUpOffersState(state: UserState) {
			state.status.getOffers = userInitialState.status.getOffers;
			state.status.getIndustries = userInitialState.status.getIndustries;
			state.status.getContestTypes = userInitialState.status.getContestTypes;

			state.offers = userInitialState.offers;
			state.getOffersParams = {
				searchContestTypeId: null,
				searchDescription: null,
				searchName: null,
				searchIndustryId: null,
				searchBookmarksOnly: null,
			};
			state.availableIndustries = userInitialState.availableIndustries;
			state.availableContestTypes = userInitialState.availableContestTypes;
		},
	},
});

export default userSlice;

export const {
	getOffersStart,
	getOffersSuccess,
	getOffersFailure,

	getAvailableIndustriesStart,
	getAvailableIndustriesSuccess,
	getAvailableIndustriesFailure,

	getAvailableContestTypesStart,
	getAvailableContestTypesSuccess,
	getAvailableContestTypesFailure,

	toggleBookmarkStart,
	toggleBookmarkSuccess,
	toggleBookmarkFailure,

	rateOfferStart,
	rateOfferSuccess,
	rateOfferFailure,

	sendChatMessageStart,
	sendChatMessageSuccess,
	sendChatMessageFailure,

	getChatMessagesStart,
	getChatMessagesSuccess,
	getChatMessagesFailure,

	cleanUpChatState,
	cleanUpOffersState,
} = userSlice.actions;
