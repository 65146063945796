import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import { OfferSearcherFormValues } from 'App/common/components/Offers/offerSearcher/models/offerSearcherFormValues';
import { StatusType } from 'App/types/requestStatus';

const { Initial } = StatusType;

export interface AdminOffersState {
	status: {
		getOffers: StatusType;
		createOffer: StatusType;
		updateOffer: StatusType;
		getContestTypes: StatusType;
		getIndustries: StatusType;
		deleteOffer: StatusType;
	};

	offers: OfferForGetOffersResponse[];
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	getOffersParams: OfferSearcherFormValues;
}

export const adminOffersInitialState: AdminOffersState = {
	status: {
		getOffers: Initial,
		createOffer: Initial,
		getIndustries: Initial,
		getContestTypes: Initial,
		updateOffer: Initial,
		deleteOffer: Initial,
	},

	offers: [],
	availableIndustries: [],
	availableContestTypes: [],
	getOffersParams: {
		searchContestTypeId: null,
		searchDescription: null,
		searchName: null,
		searchIndustryId: null,
		searchBookmarksOnly: null,
	},
};
