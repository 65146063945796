import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ContestTypeForGetContestTypes } from 'App/api/endpoints/contestTypes/responses';
import { IndustryForGetIndustries } from 'App/api/endpoints/industries/responses';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react';
import { OfferFormValues } from './models/offerFormValues';

interface OfferFormProps {
	mode: 'edit' | 'create';
	loading: boolean;
	availableIndustries: IndustryForGetIndustries[];
	availableContestTypes: ContestTypeForGetContestTypes[];
	initialValues?: OfferFormValues;
	onFinish: (values: OfferFormValues) => void;
}

const { contestTypeId, description, industryId, name } = formRules.offer;

const OfferForm = ({
	mode,
	loading,
	availableContestTypes,
	availableIndustries,
	initialValues,
	onFinish,
}: OfferFormProps) => {
	// const editMode = mode === 'edit' ? true : false;

	return (
		<Form initialValues={initialValues || null} onFinish={onFinish} layout='vertical'>
			<Row gutter={[16, 16]}>
				<Col xs={24} md={12}>
					<Form.Item
						name='industryId'
						messageVariables={{ arg: 'Branża' }}
						label='Branża'
						rules={industryId()}
					>
						<Select
							options={availableIndustries.map((a) => {
								return { label: a.name, value: a.id };
							})}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item
						name='contestTypeId'
						messageVariables={{ arg: 'Typ konkursu' }}
						label='Typ konkursu'
						rules={contestTypeId()}
					>
						<Select
							options={availableContestTypes.map((a) => {
								return { label: a.name, value: a.id };
							})}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item
						name='name'
						messageVariables={{ arg: 'Nazwa konkursu' }}
						label='Nazwa konkursu'
						rules={name()}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} md={12}>
					<Form.Item
						name='description'
						messageVariables={{ arg: 'Dokładna nazwa konkursu' }}
						label='Dokładna nazwa konkursu'
						rules={description()}
					>
						<Input.TextArea />
					</Form.Item>
				</Col>

				<Form.Item>
					<Button loading={loading} type='primary' htmlType='submit'>
						Zapisz
					</Button>
				</Form.Item>
			</Row>
		</Form>
	);
};

export default OfferForm;
