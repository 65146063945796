import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Badge, Button, Col, Result, Row, Typography } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { StatusType } from 'App/types/requestStatus';
import { cleanUpSelectedUser, cleanUpUserStatus } from 'App/pages/adminPages/usersPages/state/users.slice';
import { ArrowLeftOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { GetUserTabs } from './components/getUserTabs/GetUserTabs';
import { useTranslation } from 'react-i18next';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { Default } from 'App/common/components/Responsive/Default';
import { RootState } from 'App/globalState/root.reducer';
import { getUser } from '../../state/users.thunk';
import './styles/UserDetailsContainer.less';

interface RouteParams {
	userId: string;
}

interface UserDetailsContainerProps extends RouteComponentProps<RouteParams> {}

const { Loading } = StatusType;

const UserDetailsContainer = ({ match }: UserDetailsContainerProps) => {
	const userId = match.params.userId;
	const { t } = useTranslation(['page', 'common']);

	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector((state: RootState) => state.pages.admin.users.selectedUser);
	const usersStatus = useSelector((state: RootState) => state.pages.admin.users.status);

	useEffect(() => {
		if (!user) {
			dispatch(getUser(userId));
		}
	}, [dispatch, user, userId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpUserStatus());
			dispatch(cleanUpSelectedUser());
		};
	}, [dispatch]);

	return usersStatus.getUser === Loading ? (
		<LoadingScreen container='screen' />
	) : user ? (
		<>
			<Button className='ml-3' onClick={() => history.push('/admin/users')} icon={<ArrowLeftOutlined />}>
				{t('common:Actions.GoBack')}
			</Button>

			<Row justify='center' className='mt-5'>
				<Col>
					<Mobile>
						<Avatar size={72} icon={<UserOutlined />} />
					</Mobile>
					<Default>
						<Avatar size={128} icon={<UserOutlined />} />
					</Default>
				</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<Badge className='user-details__lockout__badge' count={user.lockoutEnabled ? <LockOutlined /> : 0}>
						<Typography.Text className='user-details__is-deleted__text' delete={user.isDeleted} strong>
							{user.firstName} {user.lastName}
						</Typography.Text>
					</Badge>
				</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<Badge
						status={user.emailConfirmed ? 'success' : 'default'}
						title={
							user.emailConfirmed
								? t('AdminPages.UsersPages.StatusConfirmed')
								: t('AdminPages.UsersPages.StatusUnConfirmed')
						}
					>
						<Typography.Text type='secondary'>{user.email}</Typography.Text>
					</Badge>
				</Col>
			</Row>
			<GetUserTabs user={user} />
		</>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('page:AdminPages.UsersPages.UserNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default UserDetailsContainer;
