import { ChatMessageForGetChatMessagesResponse } from 'App/api/endpoints/chats/requests';
import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { SendChatMessageRequest } from 'App/api/endpoints/chats/responses';
import { ReloadOutlined } from '@ant-design/icons';
import ChatList from './ChatList';
import { useForm } from 'antd/lib/form/Form';

interface ChatProps {
	fetchingMessages: boolean;
	sendingMessage: boolean;
	chatMessages: ChatMessageForGetChatMessagesResponse[];
	onGetChatMessages: () => void;
	onSendChatMessage: (values: SendChatMessageRequest) => void;
}
const Chat = ({ fetchingMessages, chatMessages, sendingMessage, onSendChatMessage, onGetChatMessages }: ChatProps) => {
	const [form] = useForm();

	return (
		<Row justify='center'>
			<Col xs={24} md={20} lg={16}>
				<Button icon={<ReloadOutlined />} block onClick={onGetChatMessages} loading={fetchingMessages}>
					Odśwież wiadomości
				</Button>
			</Col>
			<Col xs={24} md={20} lg={16}>
				<ChatList chatMessages={chatMessages} fetchingMessages={fetchingMessages} />
			</Col>
			<Col xs={24} md={20} lg={16}>
				<Form
					form={form}
					onFinish={(values) => {
						form.resetFields();
						onSendChatMessage(values);
					}}
				>
					<Form.Item
						messageVariables={{ arg: 'Treść wiadomości' }}
						className='mb-0'
						name='message'
						rules={[{ required: true, max: 2047 }]}
					>
						<Input.TextArea rows={5} placeholder='Treść wiadomości' />
					</Form.Item>
					<Form.Item>
						<Button type='primary' block size='large' loading={sendingMessage} htmlType='submit'>
							Wyślij
						</Button>
					</Form.Item>
				</Form>
			</Col>
		</Row>
	);
};

export default Chat;
