import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, notification, PageHeader, Result, Row } from 'antd';
import { OfferForGetOffersResponse } from 'App/api/endpoints/offers/responses';
import { OfferFormValues } from 'App/common/components/Offers/offerForm/models/offerFormValues';
import { OfferSearcherFormValues } from 'App/common/components/Offers/offerSearcher/models/offerSearcherFormValues';
import OfferSearcher from 'App/common/components/Offers/offerSearcher/OfferSearcher';
import OffersList from 'App/common/components/Offers/offersList/OffersList';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import Role from 'App/types/role';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { cleanUpOffersState } from '../../state/offers.slice';
import {
	createOffer,
	deleteOffer,
	getAvailableContestTypes,
	getAvailableIndustries,
	getOffers,
	updateOffer,
} from '../../state/offers.thunk';
import CreateOfferModal from '../createOffer/modals/CreateOfferModal';
import EditOfferModal from '../editOffer/modals/EditOfferModal';
import OfferRatingsModal from '../offerRatings/modals/OfferRatingsModal';

const { Failed, Loading } = StatusType;

const OffersListContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const offers = useSelector((state: RootState) => state.pages.admin.offers.offers);
	const getOffersStatus = useSelector((state: RootState) => state.pages.admin.offers.status.getOffers);
	const getOffersParams = useSelector((state: RootState) => state.pages.admin.offers.getOffersParams);

	const createOfferStatus = useSelector((state: RootState) => state.pages.admin.offers.status.createOffer);

	const availableContestTypes = useSelector((state: RootState) => state.pages.admin.offers.availableContestTypes);
	const getContestTypesStatus = useSelector((state: RootState) => state.pages.admin.offers.status.getContestTypes);

	const availableIndustries = useSelector((state: RootState) => state.pages.admin.offers.availableIndustries);
	const getIndustriesStatus = useSelector((state: RootState) => state.pages.admin.offers.status.getIndustries);

	const [editOfferModalVisible, setEditOfferModalVisible] = useState(false);
	const [createOfferModalVisible, setCreateOfferModalVisible] = useState(false);
	const [offerRatingsModalVisible, setOfferRatingsModalVisible] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState<OfferForGetOffersResponse | null>(null);

	useEffect(() => {
		dispatch(
			getOffers({
				searchContestTypeId: null,
				searchDescription: null,
				searchName: null,
				searchIndustryId: null,
				searchBookmarksOnly: null,
			})
		);
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
		return () => {
			dispatch(cleanUpOffersState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleRetry = () => {
		dispatch(getOffers(getOffersParams));
		dispatch(getAvailableContestTypes());
		dispatch(getAvailableIndustries());
	};

	const handleShowCreateOfferModal = () => {
		setCreateOfferModalVisible(true);
	};

	const handleCloseCreateOfferModal = () => {
		setCreateOfferModalVisible(false);
	};

	const handleCreateOffer = (values: OfferFormValues) => {
		const onSuccess = () => {
			notification.success({
				message: 'Sukces',
				description: 'Oferta została dodana',
			});

			dispatch(getOffers(getOffersParams));
			handleCloseCreateOfferModal();
		};

		dispatch(createOffer(values, onSuccess));
	};

	const handleShowEditOfferModal = (selectedOffer: OfferForGetOffersResponse) => {
		setEditOfferModalVisible(true);
		setSelectedOffer(selectedOffer);
	};

	const handleCloseEditOfferModal = () => {
		setEditOfferModalVisible(false);
		setSelectedOffer(null);
	};

	const handleShowOfferRatingsModal = (selectedOffer: OfferForGetOffersResponse) => {
		setSelectedOffer(selectedOffer);
		setOfferRatingsModalVisible(true);
	};

	const handleCloseOfferRatingsModal = () => {
		setOfferRatingsModalVisible(false);
		setSelectedOffer(null);
	};

	const handleEditOffer = (values: OfferFormValues) => {
		const onSuccess = () => {
			notification.success({
				message: 'Sukces',
				description: 'Oferta została zaktualizowana',
			});

			dispatch(getOffers(getOffersParams));
			handleCloseEditOfferModal();
		};

		dispatch(updateOffer(selectedOffer?.id, values, onSuccess));
	};

	const handleDeleteOffer = (offerId: string) => {
		const onSuccess = () => {
			notification.success({
				message: 'Sukces',
				description: 'Oferta została usunięta',
			});

			dispatch(getOffers(getOffersParams));
		};

		dispatch(deleteOffer(offerId, onSuccess));
	};

	const handleOfferSearch = (values: OfferSearcherFormValues) => {
		dispatch(getOffers(values));
	};

	if (getOffersStatus === Failed)
		return (
			<>
				<PageHeader onBack={() => history.goBack()} title='Oferty' />
				<Result
					status='info'
					title='Wystąpił błąd podczas pobierania ofert'
					extra={
						<Button type='primary' onClick={handleRetry}>
							Spróbuj ponownie
						</Button>
					}
				/>
			</>
		);

	return (
		<>
			<CreateOfferModal
				creating={createOfferStatus === Loading}
				visible={createOfferModalVisible}
				availableIndustries={availableIndustries}
				availableContestTypes={availableContestTypes}
				onCancel={handleCloseCreateOfferModal}
				onCreateOffer={handleCreateOffer}
			/>
			<OfferRatingsModal
				offer={selectedOffer}
				visible={offerRatingsModalVisible}
				onCancel={handleCloseOfferRatingsModal}
			/>
			<EditOfferModal
				visible={editOfferModalVisible}
				updating={false}
				availableIndustries={availableIndustries}
				availableContestTypes={availableContestTypes}
				onCancel={handleCloseEditOfferModal}
				onUpdateOffer={handleEditOffer}
				selectedOffer={selectedOffer}
			/>
			<Row className='px-3'>
				<Col xs={24}>
					<PageHeader
						onBack={() => history.goBack()}
						title='Oferty'
						subTitle='Lista ofert'
						extra={[
							<Button
								key='add-offer'
								type='primary'
								icon={<PlusOutlined />}
								loading={createOfferStatus === Loading}
								onClick={handleShowCreateOfferModal}
							>
								Nowa oferta
							</Button>,
						]}
					/>
					<Divider className='mt-0 my-3' />
				</Col>
				<Col xs={24}>
					<OfferSearcher
						initialValues={getOffersParams}
						availableIndustries={availableIndustries}
						availableContestTypes={availableContestTypes}
						loading={getOffersStatus === Loading}
						onFinish={handleOfferSearch}
					/>
				</Col>
				<Col xs={24} lg={20} xl={18} xxl={14}>
					<OffersList
						role={Role.Admin}
						loading={
							getOffersStatus === Loading ||
							getIndustriesStatus === Loading ||
							getContestTypesStatus === Loading
						}
						availableContestTypes={availableContestTypes}
						availableIndustries={availableIndustries}
						offers={offers}
						onShowEditModal={handleShowEditOfferModal}
						onDeleteOffer={handleDeleteOffer}
						onShowOfferRatingsModal={handleShowOfferRatingsModal}
					/>
				</Col>
			</Row>
		</>
	);
};

export default OffersListContainer;
